import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutline';

import {
  Grid,
  List,
  Button,
  Divider,
  ListItem,
  IconButton,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';

import useTranslation from '_helpers/useTranslation';
import Item from './Item';
import { useOpenDialog } from 'pages/MerchantDetail/MerchantDetail';

const Admins = ({ admins, merchantId }) => {
  const { t } = useTranslation();
  const opengrantAdminDialog = useOpenDialog('grantAdmin');
  const placeholder = (
    <ListItem key="noItems">
      <ListItemText
        primary={
          <>
            {t('Žiadni administrátori')}
            <Button onClick={opengrantAdminDialog}>{t('Pridať nového')}</Button>
          </>
        }
      />
    </ListItem>
  );

  return (
    <List
      dense
      subheader={
        <Grid container justify="space-between">
          <ListSubheader>{t('Administrátori predajcu')}</ListSubheader>
          <Grid item>
            <Tooltip
              title={
                <>
                  {t('Administrátorské roly')}

                  <ul>
                    <li>{t('Overovateľ - môže overovať kupóny')}</li>
                    <li>{t('Správca - môže overovat kupony a vídí štatistiky kupónov')}</li>
                  </ul>
                </>
              }
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Pridať admina')}>
              <IconButton aria-label="add" onClick={opengrantAdminDialog}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      }
    >
      <Divider />
      {admins && admins.length > 0
        ? admins.map(admin => <Item admin={admin} key={admin.id} merchantId={merchantId} />)
        : placeholder}
    </List>
  );
};

export default Admins;
