/**
 * @flow
 * @relayHash ee18f351781796c5a6dba57b191dc610
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Role = "ADMIN" | "MERCHANT" | "SUPERADMIN" | "USER" | "%future added value";
export type IdentityQueryVariables = {|
  fetch: boolean
|};
export type IdentityQueryResponse = {|
  +me?: {|
    +id: string,
    +firstName: ?string,
    +lastName: ?string,
    +role: ?Role,
  |}
|};
export type IdentityQuery = {|
  variables: IdentityQueryVariables,
  response: IdentityQueryResponse,
|};
*/

/*
query IdentityQuery(
  $fetch: Boolean!
) {
  me @include(if: $fetch) {
    id
    firstName
    lastName
    role
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'fetch',
        type: 'Boolean!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'Condition',
        passingValue: true,
        condition: 'fetch',
        selections: [
          {
            kind: 'LinkedField',
            alias: null,
            name: 'me',
            storageKey: null,
            args: null,
            concreteType: 'User',
            plural: false,
            selections: [
              {
                kind: 'ScalarField',
                alias: null,
                name: 'id',
                args: null,
                storageKey: null,
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'firstName',
                args: null,
                storageKey: null,
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'lastName',
                args: null,
                storageKey: null,
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'role',
                args: null,
                storageKey: null,
              },
            ],
          },
        ],
      },
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'IdentityQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'IdentityQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    params: {
      operationKind: 'query',
      name: 'IdentityQuery',
      id: null,
      text:
        'query IdentityQuery(\n  $fetch: Boolean!\n) {\n  me @include(if: $fetch) {\n    id\n    firstName\n    lastName\n    role\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = '13ef18a94e233521e1a1f9c145eb43c6';
module.exports = node;
