import danger from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    primary: {
      900: '#000800',
      500: '#14302c',
      100: '#3d5955',
    },
    secondary: {
      900: '#59833d',
      500: '#88b369',
      100: '#b9e598',
      A400: '#88b369',
    },
    danger,
  },
  size: {
    container: 1280,
  },
  overrides: {
    MuiButton: {
      containedSecondary: {
        color: 'white',
      },
    },
  },
});
