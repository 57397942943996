import * as React from 'react';
import { graphql } from 'relay-runtime';
import { Grid } from '@material-ui/core';
import { FastField, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import Form from '_components/Form';
import Logger from '_helpers/Logger';
import { useYup } from '_helpers/useYupMessages';
import SelectField from '_components/SelectField';
import useTranslation from '_helpers/useTranslation';
import { useStatefullMutation } from '_components/Query';
import { useOpenDialog } from 'pages/MerchantDetail/MerchantDetail';

import MerchantDialog from './MerchantDialog';

const AdminDialog = ({ admins, merchantId, open }) => {
  const closeDialog = useOpenDialog(null);
  const { t } = useTranslation();
  const Yup = useYup();

  const { pending, action } = useStatefullMutation({
    mutation: grantAdminMutation,
    variables: {
      merchantId,
    },
    messages: {
      error: t('Pridanie administrátora sa nepodarilo'),
      success: t('Administrátor bol uložený'),
    },
    updater: useAppendAdmin(merchantId),
  });

  const checkIfEmailDoesNotExist = value => {
    const foundEmail = admins.some(({ email }) => email === value);

    return foundEmail ? false : true;
  };

  return (
    <MerchantDialog
      title={t('Nový administrátor')}
      disableActions={pending}
      disableClose={pending}
      id="grantAdmin"
    >
      <Form
        id="grantAdmin"
        validationSchema={Yup.object().shape({
          role: Yup.string()
            .nullable()
            .required()
            .default('MERCHANT'),
          email: Yup.string()
            .nullable()
            .required()
            .email()
            .test(
              '',
              t('E‑mailová adresa už existuje'),
              value => !!value && checkIfEmailDoesNotExist(value)
            )
            .default(''),
        })}
        onSubmit={data => !pending && action(data).then(res => res.ok && closeDialog())}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FastField
              component={TextField}
              label={t('Email')}
              variant="outlined"
              name="email"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={SelectField}
              variant="outlined"
              name="role"
              options={[
                { value: 'MERCHANT', label: t('Overovateľ') },
                { value: 'ADMIN', label: t('Správca') },
              ]}
            />
          </Grid>
        </Grid>
      </Form>
    </MerchantDialog>
  );
};

const useAppendAdmin = merchantId => (store, res) => {
  let merchant = null;
  let newEdge = null;

  try {
    newEdge = store.getRootField('grantAdmin');
    merchant = store.get(merchantId);

    const list = merchant.getLinkedRecords('admins');
    const exists = list.findIndex(i => i._dataID === newEdge._dataID);

    if (exists === -1) {
      list.push(newEdge);
    } else {
      list[exists] = newEdge;
    }
    merchant.setLinkedRecords(list, 'admins');
  } catch (e) {
    Logger.error(e);
  }
};

const grantAdminMutation = graphql`
  mutation AdminDialogCreateMutation($merchantId: ID!, $email: String!, $role: Role!) {
    grantAdmin(merchantId: $merchantId, email: $email, role: $role) {
      firebaseUid
      firstName
      lastName
      email
      role
      id
      merchant {
        id
      }
    }
  }
`;

export default AdminDialog;
