import * as Sentry from '@sentry/browser';

import { setBearerToken } from '_components/Query/relayEnvironment';

const loginWithIdToken = ({ idToken, rememberMe }) => {
  return fetch(process.env.REACT_APP_API_HOST + '/login', {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ firebase_token: idToken, rememberMe: !!rememberMe }),
  })
    .then(res => res.json())
    .catch(e => {
      process.env.REACT_APP_SENTRY_DSN && Sentry.captureEvent(e);
      return { ok: false, ...e };
    })
    .then(data => {
      if (!process.env.REACT_APP_COOKIE_ONLY && data && data.access_token) {
        setBearerToken(data.access_token);
      }
      return { ok: true };
    });
};

export default loginWithIdToken;
