import React from 'react';
import ReactDropzone from 'react-dropzone';
import UploadIcon from '@material-ui/icons/CloudUpload';

import useTranslation from '_helpers/useTranslation';
import { makeStyles } from '@material-ui/styles';

const Dropzone = ({ width = 320, height = 320, onChange, multiple = false, accept = [] }) => {
  const { t } = useTranslation();
  const classes = useStyles({ width, height });
  const acc = typeof accept === 'string' ? accept : (accept || []).join(',');
  return (
    <ReactDropzone
      onDrop={acceptedFiles => {
        onChange && onChange(multiple ? acceptedFiles : acceptedFiles[0]);
      }}
      // accept={accept}
    >
      {({ getRootProps, getInputProps }) => {
        const inputProps = getInputProps();
        return (
          <div {...getRootProps()} className={classes.root}>
            <input {...inputProps} accept={acc} />

            <div className={classes.placeholder}>
              <UploadIcon className={classes.icon} />
              <h3>{t('Sem vlož')}</h3>
              <span>
                {t('tvoje {files} súbory,', {
                  files: accept.map(i => (i + '/' + i).split('/')[1]).join(', '),
                })}
                <br />
                {t('alebo ich ')}
                <a href="#drag&drop">{t('vyber')}</a>
              </span>
            </div>
          </div>
        );
      }}
    </ReactDropzone>
  );
};

const color = '#ccc';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: props => props.height || 'auto',
    maxWidth: props => props.width || 'auto',
    height: props => props.height || 'auto',
    width: props => props.width || 'auto',
    margin: '0 auto',
    outline: 'none',
    cursor: 'grab',
  },
  icon: {
    fontSize: '3rem',
    color,
  },
  placeholder: {
    border: `3px dashed ${color}`,
    color: color + ' !important',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '1rem',
    textAlign: 'center',
    display: 'flex',
    outline: 'none',
    minHeight: 200,
    minWidth: 200,
    width: '100%',
    height: '100%',
    '& a': {
      color,
    },
  },
  title: {
    color: '#666',
  },
}));

export default Dropzone;
