import pt from 'prop-types';
import * as React from 'react';
import {
  FirebaseAuthProvider as OriginalFirebaseAuthProvider,
  FirebaseAuthConsumer,
} from '@react-firebase/auth';

import { firebaseApp } from '_helpers/firebase';

const FirebaseAuthContext = React.createContext({ isSignedIn: false, user: null });

function FirebaseAuthProvider({ children }) {
  return (
    <OriginalFirebaseAuthProvider firebase={firebaseApp}>
      <FirebaseAuthConsumer>
        {({ isSignedIn, user }) => (
          <FirebaseAuthContext.Provider value={{ isSignedIn, user }}>
            {children}
          </FirebaseAuthContext.Provider>
        )}
      </FirebaseAuthConsumer>
    </OriginalFirebaseAuthProvider>
  );
}

FirebaseAuthProvider.propTypes = { children: pt.node.isRequired };

export { FirebaseAuthContext };

export default FirebaseAuthProvider;
