/**
 * @flow
 * @relayHash e384339b91bb4d8bb675b875c97c33ee
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ItemDeleteVirtualCouponMutationVariables = {|
  id: string
|};
export type ItemDeleteVirtualCouponMutationResponse = {|
  +deleteCouponTemplate: {|
    +id: string
  |}
|};
export type ItemDeleteVirtualCouponMutation = {|
  variables: ItemDeleteVirtualCouponMutationVariables,
  response: ItemDeleteVirtualCouponMutationResponse,
|};
*/

/*
mutation ItemDeleteVirtualCouponMutation(
  $id: ID!
) {
  deleteCouponTemplate(id: $id) {
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'deleteCouponTemplate',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'CouponTemplate',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
          },
        ],
      },
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'ItemDeleteVirtualCouponMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'ItemDeleteVirtualCouponMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    params: {
      operationKind: 'mutation',
      name: 'ItemDeleteVirtualCouponMutation',
      id: null,
      text:
        'mutation ItemDeleteVirtualCouponMutation(\n  $id: ID!\n) {\n  deleteCouponTemplate(id: $id) {\n    id\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'a6e8d0d5938ea98d27f403eb13450650';
module.exports = node;
