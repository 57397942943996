/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MerchantsShops_merchant$ref: FragmentReference;
declare export opaque type MerchantsShops_merchant$fragmentType: MerchantsShops_merchant$ref;
export type MerchantsShops_merchant = {|
  +id: string,
  +shops: $ReadOnlyArray<{|
    +id: string,
    +address: {|
      +country: string,
      +street: string,
      +number: string,
      +city: string,
      +zip: string,
    |},
  |}>,
  +$refType: MerchantsShops_merchant$ref,
|};
export type MerchantsShops_merchant$data = MerchantsShops_merchant;
export type MerchantsShops_merchant$key = {
  +$data?: MerchantsShops_merchant$data,
  +$fragmentRefs: MerchantsShops_merchant$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MerchantsShops_merchant",
  "type": "Merchant",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "shops",
      "storageKey": null,
      "args": null,
      "concreteType": "Shop",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "address",
          "storageKey": null,
          "args": null,
          "concreteType": "Address",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "country",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "street",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "number",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "city",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "zip",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c3fc10e0aef6ea6694867f2093726ea0';
module.exports = node;
