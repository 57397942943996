import IntlMessageFormat from 'intl-messageformat';

export default function useTranslation(message) {
  return {
    t: function t(message, options) {
      const msg = new IntlMessageFormat(message, `sk-SK`);
      return msg.format({
        locale: 'sk',
        ...options,
      });
    },
  };
}
