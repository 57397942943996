import React, { useEffect, useState, memo } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import {
  Grid,
  List,
  Button,
  Divider,
  ListItem,
  IconButton,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';

import useTranslation from '_helpers/useTranslation';

import Item from './Item';
import { useOpenDialog } from '../../MerchantDetail';

const VirtualCouponsList = ({ coupons, merchantId }) => {
  const [curentCoupons, setCurrentCoupons] = useState(coupons);

  const openCreateVirtualDialog = useOpenDialog('createCouponTemplate');

  useEffect(() => {
    setCurrentCoupons(coupons);
  }, [coupons]);

  const { t } = useTranslation();

  const placeholder = (
    <ListItem key="noItems">
      <ListItemText
        primary={
          <>
            {t('Žiadne kupóny')}
            <Button onClick={openCreateVirtualDialog}>{t('Pridať')}</Button>
          </>
        }
      />
    </ListItem>
  );

  return (
    <List
      dense
      subheader={
        <Grid container justify="space-between">
          <ListSubheader>{t('Virtuálne kupóny')}</ListSubheader>
          <Grid item>
            <Tooltip title={t('Vytvoriť kupón')}>
              <IconButton aria-label="create-coupon-template" onClick={openCreateVirtualDialog}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      }
    >
      <Divider />
      {curentCoupons && curentCoupons.length > 0
        ? curentCoupons.map(coupon => (
            <Item coupon={coupon} merchantId={merchantId} key={coupon.id} /> // eslint-disable-line
          )) // eslint-disable-line
        : placeholder}
    </List>
  );
};

export default memo(VirtualCouponsList);
