/**
 * @flow
 * @relayHash 2417c7e1c159789a5213431429c0bb0b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddressInput = {|
  location: GPSInput,
  country: string,
  street: string,
  number: string,
  city: string,
  zip: string,
|};
export type GPSInput = {|
  lng: number,
  lat: number,
|};
export type ShopDialogMutationVariables = {|
  merchantId: string,
  address: AddressInput,
|};
export type ShopDialogMutationResponse = {|
  +createShop: {|
    +id: string,
    +address: {|
      +location: {|
        +lng: number,
        +lat: ?number,
      |},
      +country: string,
      +number: string,
      +street: string,
      +city: string,
      +zip: string,
    |},
  |}
|};
export type ShopDialogMutation = {|
  variables: ShopDialogMutationVariables,
  response: ShopDialogMutationResponse,
|};
*/

/*
mutation ShopDialogMutation(
  $merchantId: ID!
  $address: AddressInput!
) {
  createShop(merchantId: $merchantId, address: $address) {
    id
    address {
      location {
        lng
        lat
      }
      country
      number
      street
      city
      zip
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'merchantId',
        type: 'ID!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'address',
        type: 'AddressInput!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'createShop',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'address',
            variableName: 'address',
          },
          {
            kind: 'Variable',
            name: 'merchantId',
            variableName: 'merchantId',
          },
        ],
        concreteType: 'Shop',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'address',
            storageKey: null,
            args: null,
            concreteType: 'Address',
            plural: false,
            selections: [
              {
                kind: 'LinkedField',
                alias: null,
                name: 'location',
                storageKey: null,
                args: null,
                concreteType: 'GPSLocation',
                plural: false,
                selections: [
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'lng',
                    args: null,
                    storageKey: null,
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'lat',
                    args: null,
                    storageKey: null,
                  },
                ],
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'country',
                args: null,
                storageKey: null,
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'number',
                args: null,
                storageKey: null,
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'street',
                args: null,
                storageKey: null,
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'city',
                args: null,
                storageKey: null,
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'zip',
                args: null,
                storageKey: null,
              },
            ],
          },
        ],
      },
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'ShopDialogMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'ShopDialogMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    params: {
      operationKind: 'mutation',
      name: 'ShopDialogMutation',
      id: null,
      text:
        'mutation ShopDialogMutation(\n  $merchantId: ID!\n  $address: AddressInput!\n) {\n  createShop(merchantId: $merchantId, address: $address) {\n    id\n    address {\n      location {\n        lng\n        lat\n      }\n      country\n      number\n      street\n      city\n      zip\n    }\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = '1d02ecfed9f98f5774e9790255b75646';
module.exports = node;
