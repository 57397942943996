import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

import App from './App';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line
window.version = process.env.REACT_APP_VERSION;

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, release: window.version });
}

if (process.env.REACT_APP_LOGROCKET) {
  LogRocket.init('engerau/loky-admin');

  if (process.env.REACT_APP_SENTRY_DSN) {
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// PWA
serviceWorker.unregister();
