import React, { useEffect } from 'react';
import { Checkbox as UICheckbox, FormControlLabel } from '@material-ui/core';

const CheckBox = ({ field, form, label, margin, color = 'primary', checkboxState }) => {
  useEffect(() => {
    checkboxState(false);
  }, []);

  return (
    <FormControlLabel
      onClick={e => {
        e.preventDefault();

        if (checkboxState) {
          checkboxState(!field.value);
        }

        field.onChange({ target: { value: !field.value, name: field.name } });
      }}
      margin={margin}
      label={label}
      control={
        <UICheckbox
          onClick={e => e.preventDefault()}
          checked={!!field.value}
          name={field.name}
          color={color}
          value="true"
        />
      }
    />
  );
};

export default CheckBox;
