import React, { Suspense, lazy } from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '_components/Dialog';
import useTranslation from '_helpers/useTranslation';

const ExcelImport = lazy(() => import('./ExcelImport'));

const ExcelImportModal = ({ requestClose, onSuccess, merchantId, templates, open }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={requestClose} title={t('Nahrať nové kupóny')}>
      <Suspense
        fallback={
          <div className={classes.centerAnimation}>
            <CircularProgress color="secondary" />
          </div>
        }
      >
        <ExcelImport
          requestClose={requestClose}
          merchantId={merchantId}
          templates={templates}
          onSuccess={onSuccess}
          open={open}
        />
      </Suspense>
    </Dialog>
  );
};

const useStyles = makeStyles({
  centerAnimation: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '3rem',
  },
});

export default ExcelImportModal;
