import * as React from 'react';
import { TextField } from 'formik-material-ui';
import { useYup } from '_helpers/useYupMessages';
import RemoveIcon from '@material-ui/icons/Close';
import { Grid, IconButton } from '@material-ui/core';
import { FastField as Field, FormikConsumer } from 'formik';
import MUIPlacesAutocomplete from 'mui-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';

import Form from '_components/Form';
import useTranslation from '_helpers/useTranslation';

const ShopForm = ({ onSubmit, id }) => {
  const { t } = useTranslation();
  const Yup = useYup();

  const [searchPlaceValue, setSearchPlaceValue] = React.useState('');

  const setSearchPlace = e => {
    setSearchPlaceValue(e.target.value || '');
  };

  const schema = Yup.object().shape({
    country: Yup.string()
      .nullable()
      .required()
      .default(''),
    street: Yup.string()
      .nullable()
      .required()
      .default(''),
    number: Yup.string()
      .nullable()
      .required()
      .default(''),
    city: Yup.string()
      .nullable()
      .required()
      .default(''),
    zip: Yup.string()
      .nullable()
      .required()
      .default(''),
    location: Yup.object().shape({ lat: Yup.number().default(0), lng: Yup.number().default(0) }),
  });

  return (
    <Form
      onSubmit={(data, actions) => onSubmit(data).then(_ => actions.setSubmitting(false))}
      validationSchema={schema}
      id={id}
    >
      <Grid container justify="space-between" spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <div style={{ position: 'relative', zIndex: 999 }}>
            <FormikConsumer>
              {({ setValues }) => (
                <MUIPlacesAutocomplete
                  onSuggestionSelected={suggestion => {
                    const id = suggestion.description;
                    geocodeByAddress(id).then(places => {
                      places.sort(
                        (a, b) => b.address_components.length - a.address_components.length
                      );

                      const place = places[0];
                      const location = {
                        lng: place.geometry.location.lng(),
                        lat: place.geometry.location.lat(),
                      };

                      const address = {
                        country: find(place, 'country'),
                        street: find(place, 'route'),
                        city: find(place, 'locality') || find(place, 'administrative_area_level_1'),
                        number: find(place, 'street_number'),
                        zip: find(place, 'postal_code'),
                        location,
                      };

                      setValues(address);
                    });
                  }}
                  textFieldProps={{
                    label: t('Lokalita'),
                    fullWidth: true,
                    variant: 'outlined',
                    helperText: t(
                      'Vyberte miesto pomocou automatického dopĺňania a Vaša pobočka bude mócť byť zobrazená na mapách'
                    ),
                    onChange: setSearchPlace,
                    value: searchPlaceValue,
                    InputProps: {
                      endAdornment: (
                        <IconButton onClick={() => setSearchPlaceValue('')}>
                          <RemoveIcon />
                        </IconButton>
                      ),
                    },
                  }}
                  renderTarget={() => null}
                />
              )}
            </FormikConsumer>
          </div>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Field
            component={TextField}
            label={t('Krajina')}
            variant="outlined"
            margin="dense"
            name="country"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Field
            component={TextField}
            label={t('Ulica')}
            variant="outlined"
            margin="dense"
            name="street"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Field
            component={TextField}
            label={t('Číslo')}
            variant="outlined"
            margin="dense"
            name="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Field
            component={TextField}
            label={t('Mesto')}
            variant="outlined"
            margin="dense"
            name="city"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Field
            component={TextField}
            variant="outlined"
            label={t('PSČ')}
            margin="dense"
            name="zip"
            fullWidth
          />
        </Grid>
      </Grid>
    </Form>
  );
};

function find(place, name) {
  return (place.address_components.find(i => i.types.indexOf(name) > -1) || {}).long_name || null;
}

export default ShopForm;
