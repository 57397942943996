import * as React from 'react';
import { Button } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';

import Dropzone from '_components/Dropzone';
import AvatarEditor from '_components/AvatarEditor';
import useTranslation from '_helpers/useTranslation';

import Dialog, { LeftAction } from './Dialog';

const AvatarDialog = ({ value = null, open, requestClose, onChange, title, onDelete }) => {
  const [file, setFile] = React.useState(value);
  const confirmRef = React.useRef();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      classes={{ content: classes.content }}
      title={title || t('Vyberte avatara')}
      onClose={requestClose}
      open={open}
      actions={[
        <LeftAction key="delete">
          <Button disabled={!file} key="delete" onClick={() => setFile(null)}>
            {t('Vyber inú')}
          </Button>
        </LeftAction>,
        <Tooltip
          key="tooltip"
          title={t(
            'V prípade že nevidíte požadovanú plochu na orezanie, a nedá sa odzoomovať, použite obrázok s iným pomerom strán, ideálne 1:1'
          )}
        >
          <InfoIcon />
        </Tooltip>,
        <Button key="cancel" onClick={requestClose}>
          {t('Zrušiť')}
        </Button>,
        <Button
          key="save"
          onClick={() => {
            const res = confirmRef.current && confirmRef.current();
            if (res) {
              res.then(value => onChange(value));
            } else {
              requestClose && requestClose();
            }
          }}
        >
          {t('Použiť')}
        </Button>,
      ]}
    >
      {file ? (
        <>
          <AvatarEditor value={file} confirmRef={confirmRef} />
        </>
      ) : (
        <div className={classes.dropSpace}>
          <Dropzone
            width={280}
            height={280}
            onChange={setFile}
            accept={['.jpg', '.jpeg', '.png']}
          />
        </div>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
  },
  dropSpace: {
    padding: 20,
  },
}));

export default AvatarDialog;
