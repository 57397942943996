import * as React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';

import AutocompleteSelect from './AutocompleteSelect';

const AutocompleteSelectField = ({ field, form, ...props }) => {
  return (
    <FormControl
      fullWidth
      error={!!form.errors[field.name]}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <FormGroup>
        <AutocompleteSelect
          {...props}
          {...field}
          onBlur={e => {
            form.setFieldTouched(field.name, true);
          }}
          error={form.touched[field.name] && form.errors[field.name]}
          onChange={e => {
            form.setFieldTouched(field.name, true);
            form.setFieldValue(field.name, e.target.value);
          }}
        />
      </FormGroup>
    </FormControl>
  );
};

export default AutocompleteSelectField;
