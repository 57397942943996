import * as React from 'react';
import commitMutationPromise from '_components/Query/commitMutationPromise';

import toastr from '_helpers/toastr';
/**
 * mutation Options definition
 * @typedef {Object} Options
 * @property {string} mutation
 * @property {Object} variables
 */

/**
 * mutationTrigger
 * @property {Options} options
 */
const mutationTrigger = options => {
  return commitMutationPromise(options);
};

/**
 * mutation Options definition
 * @typedef {Object} Messages
 * @property {string} error
 * @property {string} success
 */

/**
 * mutationTrigger
 * @property {Object} options
 * @property {string} options.mutation Graphql mutation
 * @property {Object} options.variables Request variables
 * @property {Object} options.messages Result notifications
 * @property {string} options.messages.error In case of error, display err toast
 * @property {string} options.messages.success In case of success, display success toast
 *
 * @returns {Promise} Promise with request result
 */
const useStatefullMutation = ({ messages: { success, error } = {}, ...options } = {}) => {
  const [pending, setPending] = React.useState(false);

  const action = (variables, moreOptions) => {
    setPending(true);
    const conf = {
      ...options,
      variables: { ...(options.variables || {}), ...(variables || {}) }, // override vars or add new from action
      ...(moreOptions || {}),
    };
    return mutationTrigger(conf)
      .then(res => {
        setPending(false);
        success && toastr({ text: success, type: 'success' });
        return { ...res, ok: true };
      })
      .catch(e => {
        setPending(false);
        error && toastr({ text: error, type: 'error' });
        if (!error) {
          throw e;
        }
        return { ok: false, error: e };
      });
  };

  return { pending, action };
};

export default useStatefullMutation;
