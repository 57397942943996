import React, { useState } from 'react';
import { FastField } from 'formik';
import { graphql } from 'relay-runtime';
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';

import Form from '_components/Form';
import CheckBox from '_components/CheckboxField';
import { useYup } from '_helpers/useYupMessages';
import useTranslation from '_helpers/useTranslation';
import { useStatefullMutation } from '_components/Query';

import Logger from '_helpers/Logger';
import MerchantDialog from './MerchantDialog';
import { useOpenDialog } from 'pages/MerchantDetail/MerchantDetail';

const CouponTemplateDialog = ({ merchantId, open, id }) => {
  const [isCheckboxChecked, setCheckboxState] = useState(false);
  const closeDialog = useOpenDialog(null);
  const { t } = useTranslation();
  const Yup = useYup();

  const { pending, action } = useStatefullMutation({
    mutation: createCoupoTempalteMutation,
    variables: {
      merchantId,
    },
    messages: {
      success: t('Nový kupón vytvorený'),
      error: t('Pridanie kupónu zlyhalo'),
    },
    updater: useAppendCouponTemplate(merchantId),
  });

  const schema = Yup.object().shape({
    value: Yup.number()
      .nullable()
      .required()
      .min(0)
      .max(9999)
      .default(''),
    valid: Yup.number()
      .nullable()
      .required()
      .default(3),
    isFree: Yup.boolean()
      .nullable()
      .default(false),
    freeCouponsLimit: Yup.number().when('isFree', {
      is: true,
      then: Yup.number()
        .required()
        .min(1)
        .max(9999),
    }),
    freePerUserLimit: Yup.number().when('isFree', {
      is: true,
      then: Yup.number()
        .required()
        .min(1)
        .max(9999),
    }),
  });

  return (
    <MerchantDialog id={id} disableClose={pending} title={t('Nový kupón')}>
      <Form
        onSubmit={data => action(schema.cast(data)).then(res => res.ok && closeDialog())}
        validationSchema={schema}
        id={id}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FastField
              InputProps={{ endAdornment: '€' }}
              component={TextField}
              label={t('Hodnota')}
              variant="outlined"
              type="number"
              name="value"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FastField
              InputProps={{ endAdornment: t('mesiace') }}
              component={TextField}
              label={t('Platnosť')}
              variant="outlined"
              name="valid"
              fullWidth
            />
            <FastField
              component={CheckBox}
              checkboxState={setCheckboxState}
              label={t('Kupón je zdarma')}
              name="isFree"
            />
          </Grid>
          {isCheckboxChecked && (
            <>
              <Grid item xs={12}>
                <FastField
                  component={TextField}
                  label={t('Počet kupónov zadarmo')}
                  variant="outlined"
                  name="freeCouponsLimit"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  component={TextField}
                  label={t('Počet kupónov zadarmo pre jedného používateľa')}
                  variant="outlined"
                  name="freePerUserLimit"
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </Form>
    </MerchantDialog>
  );
};

const useAppendCouponTemplate = merchantId => (store, res) => {
  let merchant = null;
  let newEdge = null;

  try {
    newEdge = store.getRootField('createCouponTemplate');
    merchant = store.get(merchantId);

    const list = merchant.getLinkedRecords('couponTemplates');
    list.push(newEdge);
    merchant.setLinkedRecords(list, 'couponTemplates');
  } catch (e) {
    Logger.error(e);
  }
};

const createCoupoTempalteMutation = graphql`
  mutation CouponTemplateDialogCreateMutation(
    $merchantId: ID!
    $value: Float!
    $valid: Int!
    $isFree: Boolean!
    $freeCouponsLimit: Int
    $freePerUserLimit: Int
  ) {
    createCouponTemplate(
      merchantId: $merchantId
      value: $value
      valid: $valid
      isFree: $isFree
      freeCouponsLimit: $freeCouponsLimit
      freePerUserLimit: $freePerUserLimit
    ) {
      validUntil
      virtual
      value
      id
    }
  }
`;

export default CouponTemplateDialog;
