import { FastField } from 'formik';
import Box from '@material-ui/core/Box';
import React, { useContext } from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';
// import Fb from '@material-ui/icons/Facebook';
// import Google from '@material-ui/icons/Email';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Form from '_components/Form';
import toastr from '_helpers/toastr';
import Logger from '_helpers/Logger';
import { useYup } from '_helpers/useYupMessages';
import CheckBox from '_components/CheckboxField';
import useTranslation from '_helpers/useTranslation';
import { usePasswordLogin } from '_helpers/firebase'; // useSocialLogin
import { IdentityContext } from '_helpers/Identity/Identity';

function LoginPage({ children }) {
  const { isSignedIn, setSignedIn } = useContext(IdentityContext);
  const passwordLogin = usePasswordLogin();

  // const fb = useSocialLogin('facebook');
  // const g = useSocialLogin('google');

  const classes = useStyles();
  const { t } = useTranslation();

  const setCheckboxState = () => void 0;

  if (isSignedIn) {
    return <Redirect to="/" />;
  }

  const handleSubmit = data => {
    passwordLogin(data.email, data.password, data.rememberMe)
      .then(({ ok }) => {
        if (ok) {
          setSignedIn(true);
        } else {
          toastr({ text: t('Invalid credentials'), type: 'error' });
        }
      }) // eslint-disable-line
      .catch(error => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          toastr({ text: t('User has enabled another login method'), type: 'error' });
        } else {
          toastr({ text: error?.message, type: 'error' });
          Logger.error(error);
        }
      });
  };

  const Yup = useYup();

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />

      <Form
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .nullable()
            .required()
            .email(),
          password: Yup.string()
            .nullable()
            .required()
            .min(6),
          rememberMe: Yup.boolean()
            .nullable()
            .default(false),
        })}
        className={classes.form}
        onSubmit={handleSubmit}
        id="loginForm"
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Sign in')}
          </Typography>
        </Box>

        <Box mt={3} mb={3}>
          <FastField
            component={TextField}
            label="Email Address"
            autoComplete="email"
            variant="outlined"
            margin="dense"
            name="email"
            id="email"
            fullWidth
            required
            autoFocus
          />
          <FastField
            autoComplete="current-password"
            component={TextField}
            variant="outlined"
            label="Password"
            type="password"
            name="password"
            margin="dense"
            id="password"
            fullWidth
            required
          />
          <FastField
            component={CheckBox}
            label={t('Remember me')}
            name="rememberMe"
            margin="dense"
            checkboxState={setCheckboxState}
          />
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              className={classes.submit}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              {t('Sign in')}
            </Button>
          </Grid>
          {/* <Grid item xs={6}>
            <Button
              className={classes.facebook}
              startIcon={<Fb />}
              variant="contained"
              color="primary"
              type="button"
              onClick={fb}
              fullWidth
              disabled
            >
              {t('Facebook')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.google}
              startIcon={<Google />}
              variant="contained"
              color="primary"
              type="button"
              onClick={g}
              fullWidth
              disabled
            >
              {t('Google')}
            </Button>
          </Grid> */}
        </Grid>
        <Box className={classes.links} mt={3}>
          <Link href="/auth/forgot" variant="body2">
            {t('Forgot password?')}
          </Link>

          {/* <Link href="#" variant="body2">
            {t("Don't have an account? Sign Up")}
          </Link> */}
        </Box>
      </Form>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      display: 'flex',
      height: '100%',
      width: '100%',
    },
    body: {
      backgroundColor: theme.palette.common.white,
      height: '100%',
      width: '100%',
    },
    '#root': {
      display: 'flex',
      height: '100%',
    },
  },
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
  },
  form: {
    marginTop: theme.spacing(8),
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '25rem',
    display: 'flex',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  links: {
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
  },
  facebook: {
    backgroundColor: '#3b5999', // TODO: add fb color to theme
    '&:hover': {
      backgroundColor: '#2f4373',
    },
  },
  google: {
    backgroundColor: '#bf4c4c',
    '&:hover': {
      backgroundColor: '#fefefe',
    },
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://engerau.com/">
        Engerau Labs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default LoginPage;
