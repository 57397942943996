import * as firebaseApp from 'firebase/app';
import 'firebase/auth';

import firebaseConfig from './firebaseConfig';

const persistanceType = process.env.NODE_ENV === 'test' ? 'NONE' : 'LOCAL';

firebaseApp.initializeApp(firebaseConfig);
// TODO: Set persistance to NONE and remember only boolean + call "GET /me" (there is idToken and refreshToken stored in local storage)
firebaseApp.auth().setPersistence(firebaseApp.auth.Auth.Persistence[persistanceType]);

const FacebookAuthProvider = firebaseApp.auth.FacebookAuthProvider;
const GoogleAuthProvider = firebaseApp.auth.GoogleAuthProvider;
const EmailAuthProvider = firebaseApp.auth.EmailAuthProvider;

export { firebaseApp, FacebookAuthProvider, GoogleAuthProvider, EmailAuthProvider };
