import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveIcon from '@material-ui/icons/Close';
import StoreIcon from '@material-ui/icons/Storefront';
import { graphql, createFragmentContainer } from 'react-relay';

import {
  List,
  Grid,
  Button,
  Divider,
  ListItem,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
} from '@material-ui/core';

import printAddress from '_helpers/printAddress';
import useTranslation from '_helpers/useTranslation';
import { useStatefullMutation } from '_components/Query';
import { useOpenDialog } from 'pages/MerchantDetail/MerchantDetail';

const MerchantsShops = props => {
  const {
    merchant: { id: merchantId, shops },
  } = props;
  const { t } = useTranslation();
  const openShopDialog = useOpenDialog('createShop');
  const { pending, action } = useStatefullMutation({
    mutation: deleteMutation,
    messages: {
      success: t('Prevádzka bola zmazaná'),
      error: t('Prevádzku sa nepodarilo odstrániť'),
    },
    updater: (store, response) => {
      const deletedId = response.deleteShop.deletedShopId;
      const merchant = store.get(merchantId);
      const shops = merchant.getLinkedRecords('shops') || [];
      const index = shops.findIndex(shop => shop._dataID.toUpperCase() === deletedId);
      index > -1 && shops.splice(index, 1);
      merchant.setLinkedRecords(shops, 'shops');
    },
  });

  return (
    <List
      dense
      subheader={
        <Grid container justify="space-between">
          <ListSubheader>{t('Prevádzky')}</ListSubheader>
          <Grid item>
            <Tooltip title={t('Pridať prevádzku')}>
              <IconButton aria-label="delete" onClick={openShopDialog}>
                <StoreIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      }
    >
      <Divider />
      {shops && shops.length > 0 ? (
        shops.map(shop => {
          const addr = printAddress(shop.address).split(', ');
          return (
            <ListItem key={shop.id}>
              <ListItemText primary={addr[0]} secondary={addr[1]} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => action({ id: shop.id })}
                  aria-label="delete"
                  disabled={pending}
                  edge="end"
                >
                  <RemoveIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      ) : (
        <ListItem key="noItems">
          <ListItemText
            primary={
              <>
                {t('Žiadne obchody')}
                <Button onClick={openShopDialog}>{t('Pridať')}</Button>
              </>
            }
          />
        </ListItem>
      )}
    </List>
  );
};

const deleteMutation = graphql`
  mutation MerchantsShopsDeleteMutation($id: ID!) {
    deleteShop(id: $id) {
      deletedShopId: id
    }
  }
`;

export default createFragmentContainer(MerchantsShops, {
  merchant: graphql`
    fragment MerchantsShops_merchant on Merchant {
      id
      shops {
        id
        address {
          country
          street
          number
          city
          zip
        }
      }
    }
  `,
});
