import idx from 'idx';
import * as React from 'react';
import { graphql } from 'relay-runtime';

import Table from '_components/Table';
import Query from '_components/Query';
import routerHistory from '_helpers/routerHistory';
import useTranslation from '_helpers/useTranslation';

// -------------------------------------------------------------------------------------------------

const Dashboard = () => {
  const { t } = useTranslation();

  const columns = [
    { title: t('Názov'), field: 'name', search: true },
    { title: t('Segment'), field: 'category.name' },
    {
      title: t('Stav'),
      field: 'active',
      type: 'boolean',
    },
    {
      title: t('Predané kupóny'),
      render: rowData => (rowData && rowData.stats && rowData.stats.soldCoupons) || 0,
      customSort: (a, b) => {
        const A = (a && a.stats && a.stats.soldCoupons) || 0;
        const B = (b && b.stats && b.stats.soldCoupons) || 0;
        return A - B;
      },
    },
    {
      title: t('Použité kupóny'),
      render: rowData => (rowData && rowData.stats && rowData.stats.usedCoupons) || 0,
      customSort: (a, b) => {
        const A = (a && a.stats && a.stats.usedCoupons) || 0;
        const B = (b && b.stats && b.stats.usedCoupons) || 0;
        return A - B;
      },
    },
    {
      render: rowData =>
        (((rowData.stats && rowData.stats.revenue && rowData.stats.revenue) || 0).toFixed(2) ||
          '') + '€',
      title: t('Celkový obrat'),
      field: 'stats.revenue',
    },
    {
      render: rowData => {
        const share = (rowData.share && rowData.share) || 0;
        const revenue = (rowData.stats && rowData.stats.revenue && rowData.stats.revenue) || 0;
        return (revenue * (share / 100)).toFixed(2) + `€ (${share.toFixed(2)}%)`;
      },
      title: t('Loki podiel'),
      type: 'numeric',
      field: 'share',
    },
  ];

  const actions = [];

  return (
    <Query
      query={DashboardQuery}
      render={({ data, loading }) => {
        const tableData = (idx(data, _ => _.merchants.edges) || []).map(it => ({ ...it.node }));
        return (
          <Table
            localization={{
              header: {
                actions: 'Akcie',
              },
            }}
            onRowClick={(event, rowData) => routerHistory.push(`/merchant/${rowData.id}`)}
            options={{
              paginationType: 'normal',
              actionsColumnIndex: -1,
              pageSizeOptions: [],
              // search: false,
              pageSize: 10,
            }}
            title={t('Zoznam obchodníkov')}
            actions={actions}
            columns={columns}
            data={tableData}
          />
        );
      }}
    />
  );
};

// -------------------------------------------------------------------------------------------------

const DashboardQuery = graphql`
  query DashboardQuery {
    merchants(first: 500) {
      edges {
        cursor
        node {
          ... on Merchant {
            id
            name
            category {
              id
              name
            }
            share
            active
            stats {
              soldCoupons
              usedCoupons
              revenue
            }
          }
        }
      }
    }
  }
`;

export default Dashboard;
