import idx from 'idx';
import { FormikConsumer } from 'formik';
import { graphql } from 'relay-runtime';
import { Paper, Box } from '@material-ui/core';
import React, { useContext, createContext, useState } from 'react';

import AvatarDialog from '_components/AvatarDialog';
import useTranslation from '_helpers/useTranslation';
import Query, { useStatefullMutation } from '_components/Query';

import Lists from './_lists';
import ShopDialog from './_modals/ShopDialog';
import AdminDialog from './_modals/AdminDialog';
import routerHistory from '_helpers/routerHistory';
import ExcelImportModal from './_modals/ExcelImportModal';
import CouponTemplateDialog from './_modals/CouponTemplateDialog';
import MerchantForm, { updateMerchantMutation, createMerchantMutation } from './MerchantForm';
import SecretKey from './SecretKey';

// Deliver multilevel imperative action to open dialog
const DialogContext = createContext({ openDialog: null, setOpenDialog: () => {} });

// -------------------------------------------------------------------------------------------------

const MerchantDetail = ({ match }) => {
  const [openDialog, setOpenDialog] = useState(null);

  const merchantId = match.params.id || 0;
  const { t } = useTranslation();

  const { action: handleMutation } = useStatefullMutation({
    mutation: merchantId ? updateMerchantMutation : createMerchantMutation,
    variables: { merchantId },
    messages: {
      success: merchantId ? t('Dáta obchodníka boli aktualizované') : t('Nový obchodník vytvorený'),
      error: t('Obchodníka sa nepodarilo uložiť'),
    },
  });

  const handleSubmit = variables => {
    const { image, ...data } = variables.data;
    return handleMutation(
      { data },
      image && typeof image !== 'string' ? { uploadables: { 'data.image': image } } : {}
    );
  };

  const closeDialog = () => setOpenDialog(null);

  return (
    <Query
      variables={{ merchantId, fetchMerchant: !!merchantId }}
      query={merchantDetailQuery}
      render={({ data, loading, retry }) => {
        const merchant = !loading && data && data.merchant;
        const signedInUser = !loading && data && data.me;

        if (loading) {
          return merchantId
            ? t('Načítavam detail obchodníka a zoznam kategórií')
            : t('Načítavam zoznam kategórií');
        }

        return (
          <Paper>
            <Box p={4}>
              <MerchantForm
                requestAvatarAction={() => setOpenDialog('avatar')}
                categories={idx(data, _ => _.categories) || []}
                mode={merchant ? 'edit' : 'create'}
                signedInUser={signedInUser}
                onSubmit={data =>
                  handleSubmit({ data }).then(res => {
                    if (!merchantId && res.ok) {
                      setTimeout(_ => {
                        routerHistory.push('/merchant/' + res.merchant.id);
                      }, 500);
                    }
                  })
                }
                merchant={merchant}
              >
                <Box pt={4} pb={4}>
                  <SecretKey merchantId={merchantId} />
                </Box>

                {Boolean(merchantId && data && data.merchant && !loading) && (
                  <DialogContext.Provider value={{ openDialog, setOpenDialog }}>
                    <Lists merchant={merchant} />

                    <FormikConsumer>
                      {formik => (
                        <AvatarDialog
                          onChange={file => {
                            formik.setFieldValue('image', file || null);
                            closeDialog();
                          }}
                          open={openDialog === 'avatar'}
                          requestClose={closeDialog}
                          value={formik.values.image}
                        />
                      )}
                    </FormikConsumer>
                  </DialogContext.Provider>
                )}
              </MerchantForm>

              {Boolean(merchantId && data && data.merchant && !loading) && (
                <DialogContext.Provider value={{ openDialog, setOpenDialog }}>
                  <ShopDialog merchantId={merchant.id} id="createShop" />

                  <AdminDialog admins={merchant.admins} merchantId={merchant.id} id="grantAdmin" />

                  <CouponTemplateDialog merchantId={merchant.id} id="createCouponTemplate" />

                  <ExcelImportModal
                    open={openDialog === 'importCoupons'}
                    requestClose={closeDialog}
                    merchantId={merchant.id}
                    onSuccess={retry}
                  />
                </DialogContext.Provider>
              )}
            </Box>
          </Paper>
        );
      }}
    />
  );
};

export default MerchantDetail;

const useOpenDialog = name => {
  const { setOpenDialog } = useContext(DialogContext);
  return () => setOpenDialog(name);
};

export { DialogContext, useOpenDialog };

const merchantDetailQuery = graphql`
  query MerchantDetailQuery($merchantId: ID!, $fetchMerchant: Boolean!) {
    categories {
      value: id
      label: name
    }
    me {
      role
    }
    merchant(id: $merchantId) @include(if: $fetchMerchant) {
      description
      virtual
      active
      share
      ico
      officialName
      termsUrl
      name
      id
      category {
        name
        id
      }
      image {
        url
      }
      ...MerchantsShops_merchant
      couponTemplates {
        validUntil
        value
        count
        sold
        isFree
        id
      }
      admins {
        firebaseUid
        firstName
        lastName
        email
        role
        id
      }
    }
  }
`;
