/**
 * @flow
 * @relayHash 5665c7879ff628e1ef54fdccda9da074
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MerchantsShops_merchant$ref = any;
export type Role = "ADMIN" | "MERCHANT" | "SUPERADMIN" | "USER" | "%future added value";
export type MerchantDetailQueryVariables = {|
  merchantId: string,
  fetchMerchant: boolean,
|};
export type MerchantDetailQueryResponse = {|
  +categories: $ReadOnlyArray<{|
    +value: string,
    +label: string,
  |}>,
  +me: {|
    +role: ?Role
  |},
  +merchant?: ?{|
    +description: ?string,
    +virtual: boolean,
    +active: boolean,
    +share: ?number,
    +ico: ?string,
    +officialName: ?string,
    +termsUrl: ?string,
    +name: string,
    +id: string,
    +category: {|
      +name: string,
      +id: string,
    |},
    +image: ?{|
      +url: string
    |},
    +couponTemplates: $ReadOnlyArray<{|
      +validUntil: ?number,
      +value: number,
      +count: ?number,
      +sold: number,
      +isFree: boolean,
      +id: string,
    |}>,
    +admins: $ReadOnlyArray<{|
      +firebaseUid: ?string,
      +firstName: ?string,
      +lastName: ?string,
      +email: string,
      +role: ?Role,
      +id: string,
    |}>,
    +$fragmentRefs: MerchantsShops_merchant$ref,
  |},
|};
export type MerchantDetailQuery = {|
  variables: MerchantDetailQueryVariables,
  response: MerchantDetailQueryResponse,
|};
*/

/*
query MerchantDetailQuery(
  $merchantId: ID!
  $fetchMerchant: Boolean!
) {
  categories {
    value: id
    label: name
    id
  }
  me {
    role
    id
  }
  merchant(id: $merchantId) @include(if: $fetchMerchant) {
    description
    virtual
    active
    share
    ico
    officialName
    termsUrl
    name
    id
    category {
      name
      id
    }
    image {
      url
      id
    }
    ...MerchantsShops_merchant
    couponTemplates {
      validUntil
      value
      count
      sold
      isFree
      id
    }
    admins {
      firebaseUid
      firstName
      lastName
      email
      role
      id
    }
  }
}

fragment MerchantsShops_merchant on Merchant {
  id
  shops {
    id
    address {
      country
      street
      number
      city
      zip
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'merchantId',
        type: 'ID!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'fetchMerchant',
        type: 'Boolean!',
        defaultValue: null,
      },
    ],
    v1 = {
      kind: 'ScalarField',
      alias: 'value',
      name: 'id',
      args: null,
      storageKey: null,
    },
    v2 = {
      kind: 'ScalarField',
      alias: 'label',
      name: 'name',
      args: null,
      storageKey: null,
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'role',
      args: null,
      storageKey: null,
    },
    v4 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'merchantId',
      },
    ],
    v5 = {
      kind: 'ScalarField',
      alias: null,
      name: 'description',
      args: null,
      storageKey: null,
    },
    v6 = {
      kind: 'ScalarField',
      alias: null,
      name: 'virtual',
      args: null,
      storageKey: null,
    },
    v7 = {
      kind: 'ScalarField',
      alias: null,
      name: 'active',
      args: null,
      storageKey: null,
    },
    v8 = {
      kind: 'ScalarField',
      alias: null,
      name: 'share',
      args: null,
      storageKey: null,
    },
    v9 = {
      kind: 'ScalarField',
      alias: null,
      name: 'ico',
      args: null,
      storageKey: null,
    },
    v10 = {
      kind: 'ScalarField',
      alias: null,
      name: 'officialName',
      args: null,
      storageKey: null,
    },
    v11 = {
      kind: 'ScalarField',
      alias: null,
      name: 'termsUrl',
      args: null,
      storageKey: null,
    },
    v12 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null,
    },
    v13 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null,
    },
    v14 = {
      kind: 'LinkedField',
      alias: null,
      name: 'category',
      storageKey: null,
      args: null,
      concreteType: 'MerchantCategory',
      plural: false,
      selections: [(v12 /*: any*/), (v13 /*: any*/)],
    },
    v15 = {
      kind: 'ScalarField',
      alias: null,
      name: 'url',
      args: null,
      storageKey: null,
    },
    v16 = {
      kind: 'LinkedField',
      alias: null,
      name: 'couponTemplates',
      storageKey: null,
      args: null,
      concreteType: 'CouponTemplate',
      plural: true,
      selections: [
        {
          kind: 'ScalarField',
          alias: null,
          name: 'validUntil',
          args: null,
          storageKey: null,
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'value',
          args: null,
          storageKey: null,
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'count',
          args: null,
          storageKey: null,
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'sold',
          args: null,
          storageKey: null,
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'isFree',
          args: null,
          storageKey: null,
        },
        (v13 /*: any*/),
      ],
    },
    v17 = {
      kind: 'LinkedField',
      alias: null,
      name: 'admins',
      storageKey: null,
      args: null,
      concreteType: 'User',
      plural: true,
      selections: [
        {
          kind: 'ScalarField',
          alias: null,
          name: 'firebaseUid',
          args: null,
          storageKey: null,
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'firstName',
          args: null,
          storageKey: null,
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'lastName',
          args: null,
          storageKey: null,
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'email',
          args: null,
          storageKey: null,
        },
        (v3 /*: any*/),
        (v13 /*: any*/),
      ],
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'MerchantDetailQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'categories',
          storageKey: null,
          args: null,
          concreteType: 'MerchantCategory',
          plural: true,
          selections: [(v1 /*: any*/), (v2 /*: any*/)],
        },
        {
          kind: 'LinkedField',
          alias: null,
          name: 'me',
          storageKey: null,
          args: null,
          concreteType: 'User',
          plural: false,
          selections: [(v3 /*: any*/)],
        },
        {
          kind: 'Condition',
          passingValue: true,
          condition: 'fetchMerchant',
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'merchant',
              storageKey: null,
              args: (v4 /*: any*/),
              concreteType: 'Merchant',
              plural: false,
              selections: [
                (v5 /*: any*/),
                (v6 /*: any*/),
                (v7 /*: any*/),
                (v8 /*: any*/),
                (v9 /*: any*/),
                (v10 /*: any*/),
                (v11 /*: any*/),
                (v12 /*: any*/),
                (v13 /*: any*/),
                (v14 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'image',
                  storageKey: null,
                  args: null,
                  concreteType: 'File',
                  plural: false,
                  selections: [(v15 /*: any*/)],
                },
                (v16 /*: any*/),
                (v17 /*: any*/),
                {
                  kind: 'FragmentSpread',
                  name: 'MerchantsShops_merchant',
                  args: null,
                },
              ],
            },
          ],
        },
      ],
    },
    operation: {
      kind: 'Operation',
      name: 'MerchantDetailQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'categories',
          storageKey: null,
          args: null,
          concreteType: 'MerchantCategory',
          plural: true,
          selections: [(v1 /*: any*/), (v2 /*: any*/), (v13 /*: any*/)],
        },
        {
          kind: 'LinkedField',
          alias: null,
          name: 'me',
          storageKey: null,
          args: null,
          concreteType: 'User',
          plural: false,
          selections: [(v3 /*: any*/), (v13 /*: any*/)],
        },
        {
          kind: 'Condition',
          passingValue: true,
          condition: 'fetchMerchant',
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'merchant',
              storageKey: null,
              args: (v4 /*: any*/),
              concreteType: 'Merchant',
              plural: false,
              selections: [
                (v5 /*: any*/),
                (v6 /*: any*/),
                (v7 /*: any*/),
                (v8 /*: any*/),
                (v9 /*: any*/),
                (v10 /*: any*/),
                (v11 /*: any*/),
                (v12 /*: any*/),
                (v13 /*: any*/),
                (v14 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'image',
                  storageKey: null,
                  args: null,
                  concreteType: 'File',
                  plural: false,
                  selections: [(v15 /*: any*/), (v13 /*: any*/)],
                },
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'shops',
                  storageKey: null,
                  args: null,
                  concreteType: 'Shop',
                  plural: true,
                  selections: [
                    (v13 /*: any*/),
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'address',
                      storageKey: null,
                      args: null,
                      concreteType: 'Address',
                      plural: false,
                      selections: [
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'country',
                          args: null,
                          storageKey: null,
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'street',
                          args: null,
                          storageKey: null,
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'number',
                          args: null,
                          storageKey: null,
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'city',
                          args: null,
                          storageKey: null,
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'zip',
                          args: null,
                          storageKey: null,
                        },
                      ],
                    },
                  ],
                },
                (v16 /*: any*/),
                (v17 /*: any*/),
              ],
            },
          ],
        },
      ],
    },
    params: {
      operationKind: 'query',
      name: 'MerchantDetailQuery',
      id: null,
      text:
        'query MerchantDetailQuery(\n  $merchantId: ID!\n  $fetchMerchant: Boolean!\n) {\n  categories {\n    value: id\n    label: name\n    id\n  }\n  me {\n    role\n    id\n  }\n  merchant(id: $merchantId) @include(if: $fetchMerchant) {\n    description\n    virtual\n    active\n    share\n    ico\n    officialName\n    termsUrl\n    name\n    id\n    category {\n      name\n      id\n    }\n    image {\n      url\n      id\n    }\n    ...MerchantsShops_merchant\n    couponTemplates {\n      validUntil\n      value\n      count\n      sold\n      isFree\n      id\n    }\n    admins {\n      firebaseUid\n      firstName\n      lastName\n      email\n      role\n      id\n    }\n  }\n}\n\nfragment MerchantsShops_merchant on Merchant {\n  id\n  shops {\n    id\n    address {\n      country\n      street\n      number\n      city\n      zip\n    }\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'ed56455eec7a833ada86b9a2db2a510d';
module.exports = node;
