/**
 * @flow
 * @relayHash 8eb206c290f9282479e4c403e53f5deb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MerchantInput = {|
  name: string,
  share: number,
  categoryId: string,
  active: boolean,
  description?: ?string,
  image?: ?any,
  virtual: boolean,
|};
export type createMerchantMutationVariables = {|
  data: MerchantInput
|};
export type createMerchantMutationResponse = {|
  +merchant: {|
    +id: string,
    +name: string,
    +description: ?string,
    +ico: ?string,
    +officialName: ?string,
    +termsUrl: ?string,
    +virtual: boolean,
    +category: {|
      +id: string,
      +name: string,
    |},
    +shops: $ReadOnlyArray<{|
      +id: string,
      +address: {|
        +location: {|
          +lng: number,
          +lat: ?number,
        |},
        +country: string,
        +street: string,
        +number: string,
        +city: string,
        +zip: string,
      |},
    |}>,
    +couponTemplates: $ReadOnlyArray<{|
      +id: string,
      +count: ?number,
      +value: number,
      +validUntil: ?number,
    |}>,
  |}
|};
export type createMerchantMutation = {|
  variables: createMerchantMutationVariables,
  response: createMerchantMutationResponse,
|};
*/

/*
mutation createMerchantMutation(
  $data: MerchantInput!
) {
  merchant: createMerchant(data: $data) {
    id
    name
    description
    ico
    officialName
    termsUrl
    virtual
    category {
      id
      name
    }
    shops {
      id
      address {
        location {
          lng
          lat
        }
        country
        street
        number
        city
        zip
      }
    }
    couponTemplates {
      id
      count
      value
      validUntil
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'data',
        type: 'MerchantInput!',
        defaultValue: null,
      },
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null,
    },
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null,
    },
    v3 = [
      {
        kind: 'LinkedField',
        alias: 'merchant',
        name: 'createMerchant',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
        ],
        concreteType: 'Merchant',
        plural: false,
        selections: [
          (v1 /*: any*/),
          (v2 /*: any*/),
          {
            kind: 'ScalarField',
            alias: null,
            name: 'description',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'ico',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'officialName',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'termsUrl',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'virtual',
            args: null,
            storageKey: null,
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'category',
            storageKey: null,
            args: null,
            concreteType: 'MerchantCategory',
            plural: false,
            selections: [(v1 /*: any*/), (v2 /*: any*/)],
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'shops',
            storageKey: null,
            args: null,
            concreteType: 'Shop',
            plural: true,
            selections: [
              (v1 /*: any*/),
              {
                kind: 'LinkedField',
                alias: null,
                name: 'address',
                storageKey: null,
                args: null,
                concreteType: 'Address',
                plural: false,
                selections: [
                  {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'location',
                    storageKey: null,
                    args: null,
                    concreteType: 'GPSLocation',
                    plural: false,
                    selections: [
                      {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'lng',
                        args: null,
                        storageKey: null,
                      },
                      {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'lat',
                        args: null,
                        storageKey: null,
                      },
                    ],
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'country',
                    args: null,
                    storageKey: null,
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'street',
                    args: null,
                    storageKey: null,
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'number',
                    args: null,
                    storageKey: null,
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'city',
                    args: null,
                    storageKey: null,
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'zip',
                    args: null,
                    storageKey: null,
                  },
                ],
              },
            ],
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'couponTemplates',
            storageKey: null,
            args: null,
            concreteType: 'CouponTemplate',
            plural: true,
            selections: [
              (v1 /*: any*/),
              {
                kind: 'ScalarField',
                alias: null,
                name: 'count',
                args: null,
                storageKey: null,
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'value',
                args: null,
                storageKey: null,
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'validUntil',
                args: null,
                storageKey: null,
              },
            ],
          },
        ],
      },
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'createMerchantMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'createMerchantMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/),
    },
    params: {
      operationKind: 'mutation',
      name: 'createMerchantMutation',
      id: null,
      text:
        'mutation createMerchantMutation(\n  $data: MerchantInput!\n) {\n  merchant: createMerchant(data: $data) {\n    id\n    name\n    description\n    ico\n    officialName\n    termsUrl\n    virtual\n    category {\n      id\n      name\n    }\n    shops {\n      id\n      address {\n        location {\n          lng\n          lat\n        }\n        country\n        street\n        number\n        city\n        zip\n      }\n    }\n    couponTemplates {\n      id\n      count\n      value\n      validUntil\n    }\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'b5f178ddfa161267df202332c0ab1104';
module.exports = node;
