import * as React from 'react';
import UIAvatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const Avatar = ({ alt = 'avatar', onClick, value, size = 24 }) => {
  const [url, setUrl] = React.useState();
  const valRef = React.useRef(value);

  React.useEffect(() => {
    if ((!url || valRef.current !== value) && value && typeof value !== 'string') {
      toBase64(value).then(setUrl);
      valRef.current = value;
    } else if ((!url || valRef.current !== value) && typeof value === 'string') {
      setUrl(value);
      valRef.current = value;
    }
  }, [url, value, setUrl]);

  const I = ({ url }) => (
    <UIAvatar className={classes.root} size={size} onClick={onClick} alt={alt} src={url} />
  );

  const classes = useStyles({ onClick, size });
  return <I url={url} />;
};

const useStyles = makeStyles(theme => ({
  root: {
    cursor: props => (props.onClick ? 'pointer' : null),
    height: props => props.size,
    width: props => props.size,
  },
}));

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default Avatar;
