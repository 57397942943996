import * as React from 'react';
import { graphql } from 'relay-runtime';

import ShopForm from './ShopForm';
import useTranslation from '_helpers/useTranslation';
import { useStatefullMutation } from '_components/Query';

import MerchantDialog from '../MerchantDialog';
import { useOpenDialog } from 'pages/MerchantDetail/MerchantDetail';
import Logger from '_helpers/Logger';

const ShopDialog = ({ merchantId, open }) => {
  const { t } = useTranslation();
  const closeDialog = useOpenDialog(null);

  const { pending, action } = useStatefullMutation({
    mutation: createShopMutation,
    variables: {
      merchantId,
    },
    messages: {
      success: t('Nová prevádzka vytvorená'),
      error: t('Vytvorenie prevádzky zlyhalo'),
    },
    updater: useAppendShop(merchantId),
  });

  return (
    <MerchantDialog
      title={t('Nová prevádzka')}
      disableActions={pending}
      disableClose={pending}
      id="createShop"
    >
      <ShopForm
        onSubmit={address =>
          action({
            address: {
              ...address,
              location: { lng: address.location.lng || 0, lat: address.location.lat || 0 },
            },
          }).then(res => res.ok && closeDialog())
        }
        id="createShop"
      />
    </MerchantDialog>
  );
};

const createShopMutation = graphql`
  mutation ShopDialogMutation($merchantId: ID!, $address: AddressInput!) {
    createShop(merchantId: $merchantId, address: $address) {
      id
      address {
        location {
          lng
          lat
        }
        country
        number
        street
        city
        zip
      }
    }
  }
`;

const useAppendShop = merchantId => (store, res) => {
  let merchant = null;
  let newEdge = null;

  try {
    newEdge = store.getRootField('createShop');
    merchant = store.get(merchantId);
    const list = merchant.getLinkedRecords('shops');
    list.push(newEdge);
    merchant.setLinkedRecords(list, 'shops');
  } catch (e) {
    Logger.error(e);
  }
};

export default ShopDialog;
