/**
 * @flow
 * @relayHash 253a9302b3706c7818ebc4b15bfcc600
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Role = "ADMIN" | "MERCHANT" | "SUPERADMIN" | "USER" | "%future added value";
export type AdminDialogCreateMutationVariables = {|
  merchantId: string,
  email: string,
  role: Role,
|};
export type AdminDialogCreateMutationResponse = {|
  +grantAdmin: {|
    +firebaseUid: ?string,
    +firstName: ?string,
    +lastName: ?string,
    +email: string,
    +role: ?Role,
    +id: string,
    +merchant: ?{|
      +id: string
    |},
  |}
|};
export type AdminDialogCreateMutation = {|
  variables: AdminDialogCreateMutationVariables,
  response: AdminDialogCreateMutationResponse,
|};
*/

/*
mutation AdminDialogCreateMutation(
  $merchantId: ID!
  $email: String!
  $role: Role!
) {
  grantAdmin(merchantId: $merchantId, email: $email, role: $role) {
    firebaseUid
    firstName
    lastName
    email
    role
    id
    merchant {
      id
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'merchantId',
        type: 'ID!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'email',
        type: 'String!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'role',
        type: 'Role!',
        defaultValue: null,
      },
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null,
    },
    v2 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'grantAdmin',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'email',
            variableName: 'email',
          },
          {
            kind: 'Variable',
            name: 'merchantId',
            variableName: 'merchantId',
          },
          {
            kind: 'Variable',
            name: 'role',
            variableName: 'role',
          },
        ],
        concreteType: 'User',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'firebaseUid',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'firstName',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'lastName',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'email',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'role',
            args: null,
            storageKey: null,
          },
          (v1 /*: any*/),
          {
            kind: 'LinkedField',
            alias: null,
            name: 'merchant',
            storageKey: null,
            args: null,
            concreteType: 'Merchant',
            plural: false,
            selections: [(v1 /*: any*/)],
          },
        ],
      },
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'AdminDialogCreateMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v2 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'AdminDialogCreateMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v2 /*: any*/),
    },
    params: {
      operationKind: 'mutation',
      name: 'AdminDialogCreateMutation',
      id: null,
      text:
        'mutation AdminDialogCreateMutation(\n  $merchantId: ID!\n  $email: String!\n  $role: Role!\n) {\n  grantAdmin(merchantId: $merchantId, email: $email, role: $role) {\n    firebaseUid\n    firstName\n    lastName\n    email\n    role\n    id\n    merchant {\n      id\n    }\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = '8f555643ab77f8ed64827230432f5ea7';
module.exports = node;
