import * as React from 'react';
import { graphql } from 'relay-runtime';

import toastr from '_helpers/toastr';
import Query from '_components/Query';
import useTranslation from '_helpers/useTranslation';
import { FirebaseAuthContext } from '_helpers/firebase';
import loginWithIdToken from '_helpers/loginWithIdToken';

const IdentityContext = React.createContext({
  isSignedIn: false,
  isAdmin: false,
  isSuperAdmin: false,
  loading: false,
  idToken: null,
  user: null,
});

const IS_SIGNED_IN = 'loggedUid';

function Identity({ children }) {
  const [init, setInit] = React.useState(localStorage.getItem(IS_SIGNED_IN) === '1');
  const { user: fireUser } = React.useContext(FirebaseAuthContext);
  const [isSignedIn, setSignedIn] = React.useState(false);

  const timerRef = React.useRef(null);
  const { t } = useTranslation();

  const handleSignIn = React.useCallback(
    is => {
      localStorage.setItem(IS_SIGNED_IN, is ? '1' : null);
      setSignedIn(!!is);
    },
    [setSignedIn]
  );

  React.useEffect(() => {
    if (init && !timerRef.current) {
      timerRef.current = setTimeout(_ => {
        setInit(false);
        toastr({
          text: t('Platnosť prihlásenia vypršala, prosím prihláste sa znovu'),
          type: 'error',
        });
      }, 10000); // set timeout
    }

    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, [init]);

  React.useEffect(() => {
    if (init && fireUser && !isSignedIn) {
      fireUser.getIdToken().then(idToken => {
        idToken &&
          loginWithIdToken({ idToken })
            .then(_ => {
              timerRef.current && clearTimeout(timerRef.current);
              timerRef.current = null;
              handleSignIn(true);
              setInit(false);
            })
            .catch(e => {
              handleSignIn(false);
              setInit(false);
            });
      });
    }
  }, [isSignedIn, init, fireUser, setInit]);

  return React.useMemo(
    () => (
      <Query
        variables={{ fetch: isSignedIn }}
        query={identityQuery}
        render={({ data, loading }) => {
          const value = {
            user: (isSignedIn && data && data.me) || undefined,
            isSignedIn: isSignedIn && Boolean(data && data.me),
            isAdmin: ['ADMIN', 'SUPERADMIN'].indexOf(data && data?.me?.role) > -1,
            isSuperAdmin: ['SUPERADMIN'].indexOf(data && data?.me?.role) > -1,
            setSignedIn: handleSignIn,
            loading: init || loading,
          };

          return <IdentityContext.Provider value={value}>{children}</IdentityContext.Provider>;
        }}
      />
    ),
    [isSignedIn, init]
  );
}

const identityQuery = graphql`
  query IdentityQuery($fetch: Boolean!) {
    me @include(if: $fetch) {
      id
      firstName
      lastName
      role
    }
  }
`;

export { IdentityContext };

export default React.memo(Identity);
