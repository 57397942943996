import * as React from 'react';
import { graphql } from 'relay-runtime';
import RemoveIcon from '@material-ui/icons/Close';
import { ListItem, IconButton, ListItemText, ListItemSecondaryAction } from '@material-ui/core';

import useTranslation from '_helpers/useTranslation';
import { useStatefullMutation } from '_components/Query';

const Item = ({ merchantId, admin }) => {
  const { t } = useTranslation();
  const { pending, action } = useStatefullMutation({
    mutation: deleteMutation,
    variables: { id: admin.id },
    messages: {
      success: t('Administrátor bol úspešne zmazaný'),
      error: t('Administrátor bol úspešne zmazaný'),
    },
    updater: (store, response) => {
      const deletedId = response.stripAdmin.id;
      const merchant = store.get(merchantId);
      const admins = merchant.getLinkedRecords('admins') || [];
      const index = admins.findIndex(it => it._dataID.toUpperCase() === deletedId);
      index > -1 && admins.splice(index, 1);
      merchant.setLinkedRecords(admins, 'admins');
    },
  });

  return (
    <ListItem key={admin.id}>
      <ListItemText
        primary={
          (getFullName(admin) || admin.email) + (!admin.firebaseUid ? ' (Neaktivovaný)' : '')
        }
        secondary={<i>{admin.role === 'MERCHANT' ? 'Overovateľ' : 'Správca'}</i>}
      />
      <ListItemSecondaryAction>
        <IconButton disabled={pending} aria-label="delete" edge="end" onClick={action}>
          <RemoveIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const deleteMutation = graphql`
  mutation ItemstripAdminMutation($id: ID!) {
    stripAdmin(id: $id) {
      id
    }
  }
`;

const getFullName = user => {
  return [user.firstName, user.lastName].filter(i => i).join(' ');
};

export default Item;
