const multipartRequestMiddleware = next => req => {
  const {
    operation: { text: query },
    uploadables,
    variables,
    id,
  } = req;

  if (uploadables) {
    const graphQLMultipart = new FormData();

    const fileMap = [];

    const writeMapFromFileAndMarkVariable = (searchable, parents) => {
      Object.keys(searchable).forEach(key => {
        const currentValue = searchable[key];

        if (
          typeof currentValue === 'object' &&
          (currentValue.constructor === Object || currentValue.constructor === Array)
        ) {
          // Recursive
          writeMapFromFileAndMarkVariable(currentValue, [...parents, key]);
        } else {
          // Consider the non-plain objects inside uplodables as File data.

          fileMap.push({
            operationPath: ['variables', ...parents, key].join('.'),
            file: currentValue,
          });

          // Synchronize variable with uploadables.
          let currentDepthVariable = { ...variables };

          parents.forEach(parent => {
            if (!currentDepthVariable[parent]) {
              currentDepthVariable[parent] = {};
            }

            currentDepthVariable = currentDepthVariable[parent];
          });

          currentDepthVariable[key] = null; // Spec: Value of file key should be null.
        }
      });
    };

    writeMapFromFileAndMarkVariable(uploadables, []);

    // eslint-disable-next-line no-unused-vars
    const operations = {
      variables,
      query,
      id,
    };

    graphQLMultipart.append(
      'operations',
      JSON.stringify(operations)
        .replace(/\\n/g, '')
        .replace(/[ ]{2,}/g, ' ')
    );

    graphQLMultipart.append(
      'map',
      JSON.stringify(
        fileMap.reduce(
          (reducedMap, value, index) => ({ ...reducedMap, [index]: [value.operationPath] }),
          {}
        )
      )
    );

    fileMap.forEach((mapValue, index) => {
      graphQLMultipart.append(index, mapValue.file);
    });

    delete req.fetchOpts.headers['Content-Type'];

    req.fetchOpts.body = graphQLMultipart;
  }

  return next(req);
};

export default multipartRequestMiddleware;
