/**
 * @flow
 * @relayHash 6d21215a8f2d1e9cd48fb778a8cce3b1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CategoryModalCreateMutationVariables = {|
  name: string
|};
export type CategoryModalCreateMutationResponse = {|
  +createMerchantCategory: {|
    +id: string,
    +name: string,
  |}
|};
export type CategoryModalCreateMutation = {|
  variables: CategoryModalCreateMutationVariables,
  response: CategoryModalCreateMutationResponse,
|};
*/

/*
mutation CategoryModalCreateMutation(
  $name: String!
) {
  createMerchantCategory(name: $name) {
    id
    name
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'name',
        type: 'String!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'createMerchantCategory',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'name',
            variableName: 'name',
          },
        ],
        concreteType: 'MerchantCategory',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'name',
            args: null,
            storageKey: null,
          },
        ],
      },
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'CategoryModalCreateMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'CategoryModalCreateMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    params: {
      operationKind: 'mutation',
      name: 'CategoryModalCreateMutation',
      id: null,
      text:
        'mutation CategoryModalCreateMutation(\n  $name: String!\n) {\n  createMerchantCategory(name: $name) {\n    id\n    name\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = '083d6b8a5f14df40467400e586980102';
module.exports = node;
