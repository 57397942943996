import * as React from 'react';
import proptypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';

const Form = ({ id, children, validationSchema, initialValues, onSubmit, tag, ...props }) => {
  const inits = React.useMemo(() => (validationSchema && validationSchema.cast()) || {}, [
    validationSchema,
  ]);

  const initials = React.useMemo(
    () =>
      initialValues ? Object.keys(inits).reduce(initialsReducer(initialValues), inits) : inits,
    [inits, initialValues]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(data, actions) => {
        return new Promise(finish => {
          const res = onSubmit(validationSchema ? validationSchema.cast(data) : data, actions);
          if (res && typeof res === 'object' && typeof res.then === 'function') {
            res
              .catch(e => e)
              .then(_ => {
                finish();
              });
          } else {
            finish();
          }
        });
      }}
      initialValues={initials}
      {...props}
      enableReinitialize
    >
      <FormikForm id={id}>
        <input type="hidden" value="something" />
        <>
          {children}
          {/* TODO: style button */}
          <button type="submit" style={{ position: 'fixed', left: -9999, top: -9999 }} />
        </>
      </FormikForm>
    </Formik>
  );
};

const initialsReducer = initialValues => (acc, k) =>
  initialValues.hasOwnProperty(k) ? { ...acc, [k]: initialValues[k] } : acc;

Form.propTypes = {
  id: proptypes.string.isRequired,
};

export default Form;
