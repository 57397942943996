import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { firebaseApp } from '_helpers/firebase';
import useTranslation from '_helpers/useTranslation';
import { IdentityContext } from '_helpers/Identity/Identity';

function LogoutPage({ children }) {
  const { isSignedIn, setSignedIn } = React.useContext(IdentityContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    firebaseApp.auth().signOut();
    setSignedIn(false);
  }, []);

  return isSignedIn ? t('Načítavam...') : <Redirect to="/auth/login" />;
}

export default LogoutPage;
