import * as React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles, Switch } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function LabeledSwitchField({ label, field, form, InputProps = {}, ...props }) {
  const { endAdornment, ...restInputProps } = InputProps;
  const { end, ...classes } = useStyles();

  return (
    <FormControl fullWidth style={{ display: 'flex', justifyContent: 'center' }}>
      <FormGroup>
        <FormControlLabel
          classes={classes}
          control={
            <Switch
              {...props}
              {...restInputProps}
              onClick={e => {
                form.setFieldValue(field.name, !field.value);
              }}
              checked={!!field.value}
              value="check-value"
              color="primary"
            />
          }
          label={
            <>
              {typeof label === 'function' ? label(field.value) : label}
              {end && (
                <span onClick={e => e.preventDefault()} className={end}>
                  {endAdornment}
                </span>
              )}
            </>
          }
        />
      </FormGroup>
    </FormControl>
  );
}

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
  },
  label: {
    alignItems: 'center',
    display: 'flex',
    width: 'auto',
    flex: 1,
  },
  end: {
    marginLeft: 'auto',
    display: 'flex',
  },
});

export default LabeledSwitchField;
