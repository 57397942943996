import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Layout from '_components/Layout';
import Dashboard from 'pages/Dashboard';
import { IdentityContext } from '_helpers/Identity/Identity';

import MerchantDetail from './pages/MerchantDetail';
import CategoryModal from './pages/_components/CategoryModal/CategoryModal';
import NotAuthorized from './pages/NotAuthorized';

const AdminWrapper = () => {
  const [categoryModal, setOpenCategoryModal] = React.useState(false);
  const { isAdmin } = React.useContext(IdentityContext);

  return (
    <Layout requestCreateCategory={() => setOpenCategoryModal(true)}>
      {isAdmin ? (
        <>
          <Switch>
            <Route path="/merchant/:id?" component={MerchantDetail} />
            <Route component={Dashboard} />
          </Switch>
          <CategoryModal requestClose={() => setOpenCategoryModal(false)} open={categoryModal} />
        </>
      ) : (
        <NotAuthorized />
      )}
    </Layout>
  );
};

export default AdminWrapper;
