import { commitMutation } from 'react-relay';

import relayEnviroment from './relayEnvironment';

export default function commitMutationPromise(options: {
  mutation: *,
  variables?: { [string]: * },
}): Promise<*> {
  return new Promise((resolve, reject) => {
    commitMutation(relayEnviroment, {
      ...options,
      onCompleted: (response, errors) => {
        if (errors) {
          reject(errors);
          return;
        }
        resolve(response);
      },
      onError: errors => {
        reject(errors);
      },
    });
  });
}
