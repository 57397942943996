/**
 * @flow
 * @relayHash dfa08996925c905a8054bfc4056f2b2c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SoldCouponsQueryVariables = {|
  merchantId?: ?string,
  from: any,
  to: any,
|};
export type SoldCouponsQueryResponse = {|
  +soldCoupons: ?$ReadOnlyArray<?{|
    +id: string,
    +value: number,
    +created: any,
  |}>
|};
export type SoldCouponsQuery = {|
  variables: SoldCouponsQueryVariables,
  response: SoldCouponsQueryResponse,
|};
*/

/*
query SoldCouponsQuery(
  $merchantId: ID
  $from: Date!
  $to: Date!
) {
  soldCoupons(merchantId: $merchantId, from: $from, to: $to) {
    id
    value
    created
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'merchantId',
        type: 'ID',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'from',
        type: 'Date!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'to',
        type: 'Date!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'soldCoupons',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'from',
            variableName: 'from',
          },
          {
            kind: 'Variable',
            name: 'merchantId',
            variableName: 'merchantId',
          },
          {
            kind: 'Variable',
            name: 'to',
            variableName: 'to',
          },
        ],
        concreteType: 'Coupon',
        plural: true,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'value',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'created',
            args: null,
            storageKey: null,
          },
        ],
      },
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'SoldCouponsQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'SoldCouponsQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    params: {
      operationKind: 'query',
      name: 'SoldCouponsQuery',
      id: null,
      text:
        'query SoldCouponsQuery(\n  $merchantId: ID\n  $from: Date!\n  $to: Date!\n) {\n  soldCoupons(merchantId: $merchantId, from: $from, to: $to) {\n    id\n    value\n    created\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'b4568e05da4400f5b21046943ff84882';
module.exports = node;
