/**
 * @flow
 * @relayHash 8f59b114a8dc394cabfb8e3ddbe5b47e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CouponTemplateDialogCreateMutationVariables = {|
  merchantId: string,
  value: number,
  valid: number,
  isFree: boolean,
  freeCouponsLimit?: ?number,
  freePerUserLimit?: ?number,
|};
export type CouponTemplateDialogCreateMutationResponse = {|
  +createCouponTemplate: {|
    +validUntil: ?number,
    +virtual: boolean,
    +value: number,
    +id: string,
  |}
|};
export type CouponTemplateDialogCreateMutation = {|
  variables: CouponTemplateDialogCreateMutationVariables,
  response: CouponTemplateDialogCreateMutationResponse,
|};
*/

/*
mutation CouponTemplateDialogCreateMutation(
  $merchantId: ID!
  $value: Float!
  $valid: Int!
  $isFree: Boolean!
  $freeCouponsLimit: Int
  $freePerUserLimit: Int
) {
  createCouponTemplate(merchantId: $merchantId, value: $value, valid: $valid, isFree: $isFree, freeCouponsLimit: $freeCouponsLimit, freePerUserLimit: $freePerUserLimit) {
    validUntil
    virtual
    value
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'merchantId',
        type: 'ID!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'value',
        type: 'Float!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'valid',
        type: 'Int!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'isFree',
        type: 'Boolean!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'freeCouponsLimit',
        type: 'Int',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'freePerUserLimit',
        type: 'Int',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'createCouponTemplate',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'freeCouponsLimit',
            variableName: 'freeCouponsLimit',
          },
          {
            kind: 'Variable',
            name: 'freePerUserLimit',
            variableName: 'freePerUserLimit',
          },
          {
            kind: 'Variable',
            name: 'isFree',
            variableName: 'isFree',
          },
          {
            kind: 'Variable',
            name: 'merchantId',
            variableName: 'merchantId',
          },
          {
            kind: 'Variable',
            name: 'valid',
            variableName: 'valid',
          },
          {
            kind: 'Variable',
            name: 'value',
            variableName: 'value',
          },
        ],
        concreteType: 'CouponTemplate',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'validUntil',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'virtual',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'value',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
          },
        ],
      },
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'CouponTemplateDialogCreateMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'CouponTemplateDialogCreateMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    params: {
      operationKind: 'mutation',
      name: 'CouponTemplateDialogCreateMutation',
      id: null,
      text:
        'mutation CouponTemplateDialogCreateMutation(\n  $merchantId: ID!\n  $value: Float!\n  $valid: Int!\n  $isFree: Boolean!\n  $freeCouponsLimit: Int\n  $freePerUserLimit: Int\n) {\n  createCouponTemplate(merchantId: $merchantId, value: $value, valid: $valid, isFree: $isFree, freeCouponsLimit: $freeCouponsLimit, freePerUserLimit: $freePerUserLimit) {\n    validUntil\n    virtual\n    value\n    id\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = '5abcb74ebf34560fcab8605ed468bf0b';
module.exports = node;
