import * as React from 'react';
import Slider from '@material-ui/core/Slider';
import OriginalvatarEditor from 'react-avatar-editor';
import grey from '@material-ui/core/colors/grey';
import ImageIcon from '@material-ui/icons/Image';
import { makeStyles } from '@material-ui/core/styles';

const AvatarEditor = ({ value, size = 280, border = 20, radius = 20, confirmRef }) => {
  const [scale, setScale] = React.useState(120);
  const classes = useStyles({ size, border });
  const editor = React.useRef(null);

  const onClickSave = () => {
    if (editor.current) {
      return new Promise(finish => {
        const canvas = editor.current.getImageScaledToCanvas().toDataURL();
        // res(canvas);
        fetch(canvas)
          .then(res => res.blob())
          .then(blob => {
            const f = new File([blob], 'image.png', { type: 'image/png' });
            finish(f);
          });
      });
    }
  };

  if (confirmRef) {
    confirmRef.current = onClickSave;
  }

  return (
    <div className={classes.root}>
      <div className={classes.imageWrapper}>
        <OriginalvatarEditor
          borderRadius={radius}
          scale={scale / 100}
          border={border}
          height={size}
          image={value}
          width={size}
          ref={editor}
        />
      </div>
      <div className={classes.sliderWrapper}>
        <ImageIcon fontSize="small" className={classes.icon} />
        <Slider
          onChange={(e, val) => setScale(Number(val))}
          aria-label="custom thumb label"
          className={classes.slider}
          marks={[{ value: 100 }]}
          defaultValue={120}
          step={10}
          max={250}
          min={50}
        />
        <ImageIcon className={classes.icon} fontSize="large" />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    display: 'flex',
    width: 'auto',
  },
  imageWrapper: {
    backgroundColor: '#666666',
    backgroundImage:
      'linear-gradient(45deg, #777777 25%, transparent 25%), linear-gradient(-45deg, #777777 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #777777 75%), linear-gradient(-45deg, transparent 75%, #777777 75%)',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
    backgroundSize: '20px 20px',
    display: 'flex',
  },

  sliderWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    display: 'flex',
    width: props => props.size + props.border * 2,
  },
  icon: {
    color: grey.A200,
  },
  slider: { margin: '0 0.5rem' },
}));

export default AvatarEditor;
