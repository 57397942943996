import { graphql } from 'relay-runtime';
import React from 'react';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/styles';

import useTranslation from '_helpers/useTranslation';
import Query from '_components/Query';

const UsedCoupons = ({ merchantId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const today = new Date();

  // TODO: replace from with some value in future
  return (
    <Query
      variables={{ merchantId, from: '2020-10-09T00:00:00Z', to: today.toISOString() }}
      query={usedCouponsQuery}
      render={({ data, loading, retry }) => {
        const usedCoupons = !loading && data && data.usedCoupons;

        if ((usedCoupons && !usedCoupons.length) || loading) {
          return null;
        }

        const headers = [
          { label: t('ID'), key: 'couponId' },
          { label: t('Suma'), key: 'value' },
          { label: t('Dátum'), key: 'at' },
        ];

        const usedCouponArray =
          usedCoupons &&
          usedCoupons.length > 0 &&
          usedCoupons.map(usedCoupon => {
            const { at, value, coupon } = usedCoupon || {};
            const couponId = coupon.id || {};
            return { couponId, value, at };
          });

        return (
          <CSVLink
            className={classes.csvLink}
            data={usedCouponArray}
            headers={headers}
            target="_blank"
            filename={'coupons_usage.csv'}
          >
            {t('Použité kupóny CSV')}
          </CSVLink>
        );
      }}
    />
  );
};

const useStyles = makeStyles(theme => ({
  csvLink: {
    color: '#88b369',
    padding: '6px 16px',
    fontSize: '0.875rem',
    marginRight: 10,
    fontWeight: 500,
    lineHeight: 1.75,
  },
}));

export default UsedCoupons;

const usedCouponsQuery = graphql`
  query UsedCouponsQuery($merchantId: ID, $from: Date!, $to: Date!) {
    usedCoupons(merchantId: $merchantId, from: $from, to: $to) {
      at
      value
      coupon {
        id
      }
    }
  }
`;
