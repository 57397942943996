import * as React from 'react';
import { Paper, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useTranslation from '_helpers/useTranslation';

const NotAuthorized = () => {
  const { t } = useTranslation();

  return (
    <Paper>
      <Box p={4}>
        <Typography variant="h5">{t('Nemáte povolenie na prístup týchto záznamov')}</Typography>
      </Box>
    </Paper>
  );
};

export default NotAuthorized;
