import * as React from 'react';
import { Button } from '@material-ui/core';

import Dialog from '_components/Dialog';
import useTranslation from '_helpers/useTranslation';

import { DialogContext } from '../MerchantDetail';

const MerchantDialog = ({ id, title, disableActions, confirmButtonText, children }) => {
  const { t } = useTranslation();
  const { openDialog, setOpenDialog } = React.useContext(DialogContext);

  const handleClose = () => !disableActions && setOpenDialog(null);

  return (
    <Dialog
      actions={[
        <Button key="cancel" onClick={handleClose} disabled={disableActions}>
          {t('Zatvoriť')}
        </Button>,
        <Button
          disabled={disableActions}
          variant="contained"
          color="primary"
          type="submit"
          key="add"
          form={id}
        >
          {confirmButtonText || t('Potvrdiť')}
        </Button>,
      ]}
      open={openDialog === id}
      onClose={handleClose}
      title={title}
    >
      {children}
    </Dialog>
  );
};

export default MerchantDialog;
