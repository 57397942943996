import * as React from 'react';
import {
  Dialog as UIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

function Dialog({ classes, open, maxWidth, onClose, children, title, actions }) {
  const c = rootClasses();
  return (
    <UIDialog
      aria-labelledby="customized-dialog-title"
      classes={{ paper: c.paper }}
      maxWidth={maxWidth || 'sm'}
      onClose={onClose}
      open={open}
    >
      {title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
      <DialogContent dividers className={(classes || {}).content}>
        {children}
      </DialogContent>
      {actions && <DialogActions className={c.actions}>{actions}</DialogActions>}
    </UIDialog>
  );
}

export function LeftAction({ children }) {
  const classes = leftClasses();

  return <div className={classes.root}>{children}</div>;
}

const leftClasses = makeStyles(theme => ({
  root: { marginRight: 'auto' },
}));

const rootClasses = makeStyles(theme => ({
  paper: { margin: '2rem' },
  actions: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiButtonBase-root': {
        display: 'block',
        width: '100%',
        marginLeft: 0,
      },
    },
  },
}));

export default Dialog;
