import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ForgotPassword from './ForgotPassword';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';

function Auth({ children, match }) {
  return (
    <Switch>
      <Route path={`${match.path}/logout`} component={LogoutPage} />
      <Route path={`${match.path}/login`} component={LoginPage} />
      <Route path={`${match.path}/forgot`} component={ForgotPassword} />
      <Redirect to={`${match.path}/login`} />
    </Switch>
  );
}

Auth.propTypes = {};

export default Auth;
