/**
 * @flow
 * @relayHash 21ede863591b40261967d05bf70e3b91
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DashboardQueryVariables = {||};
export type DashboardQueryResponse = {|
  +merchants: {|
    +edges: $ReadOnlyArray<{|
      +cursor: string,
      +node: {|
        +id?: string,
        +name?: string,
        +category?: {|
          +id: string,
          +name: string,
        |},
        +share?: ?number,
        +active?: boolean,
        +stats?: ?{|
          +soldCoupons: number,
          +usedCoupons: number,
          +revenue: number,
        |},
      |},
    |}>
  |}
|};
export type DashboardQuery = {|
  variables: DashboardQueryVariables,
  response: DashboardQueryResponse,
|};
*/

/*
query DashboardQuery {
  merchants(first: 500) {
    edges {
      cursor
      node {
        __typename
        ... on Merchant {
          id
          name
          category {
            id
            name
          }
          share
          active
          stats {
            soldCoupons
            usedCoupons
            revenue
          }
        }
        ... on Node {
          id
        }
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'Literal',
        name: 'first',
        value: 500,
      },
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'cursor',
      args: null,
      storageKey: null,
    },
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null,
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null,
    },
    v4 = {
      kind: 'LinkedField',
      alias: null,
      name: 'category',
      storageKey: null,
      args: null,
      concreteType: 'MerchantCategory',
      plural: false,
      selections: [(v2 /*: any*/), (v3 /*: any*/)],
    },
    v5 = {
      kind: 'ScalarField',
      alias: null,
      name: 'share',
      args: null,
      storageKey: null,
    },
    v6 = {
      kind: 'ScalarField',
      alias: null,
      name: 'active',
      args: null,
      storageKey: null,
    },
    v7 = {
      kind: 'LinkedField',
      alias: null,
      name: 'stats',
      storageKey: null,
      args: null,
      concreteType: 'MerchantStats',
      plural: false,
      selections: [
        {
          kind: 'ScalarField',
          alias: null,
          name: 'soldCoupons',
          args: null,
          storageKey: null,
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'usedCoupons',
          args: null,
          storageKey: null,
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'revenue',
          args: null,
          storageKey: null,
        },
      ],
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'DashboardQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: [],
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'merchants',
          storageKey: 'merchants(first:500)',
          args: (v0 /*: any*/),
          concreteType: 'Connection',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'edges',
              storageKey: null,
              args: null,
              concreteType: 'Edge',
              plural: true,
              selections: [
                (v1 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'node',
                  storageKey: null,
                  args: null,
                  concreteType: null,
                  plural: false,
                  selections: [
                    {
                      kind: 'InlineFragment',
                      type: 'Merchant',
                      selections: [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    operation: {
      kind: 'Operation',
      name: 'DashboardQuery',
      argumentDefinitions: [],
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'merchants',
          storageKey: 'merchants(first:500)',
          args: (v0 /*: any*/),
          concreteType: 'Connection',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'edges',
              storageKey: null,
              args: null,
              concreteType: 'Edge',
              plural: true,
              selections: [
                (v1 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'node',
                  storageKey: null,
                  args: null,
                  concreteType: null,
                  plural: false,
                  selections: [
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: '__typename',
                      args: null,
                      storageKey: null,
                    },
                    (v2 /*: any*/),
                    {
                      kind: 'InlineFragment',
                      type: 'Merchant',
                      selections: [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    params: {
      operationKind: 'query',
      name: 'DashboardQuery',
      id: null,
      text:
        'query DashboardQuery {\n  merchants(first: 500) {\n    edges {\n      cursor\n      node {\n        __typename\n        ... on Merchant {\n          id\n          name\n          category {\n            id\n            name\n          }\n          share\n          active\n          stats {\n            soldCoupons\n            usedCoupons\n            revenue\n          }\n        }\n        ... on Node {\n          id\n        }\n      }\n    }\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'fdb70ffbbc5a342ba49953641bb02684';
module.exports = node;
