import { graphql } from 'relay-runtime';

const updateMerchantMutation = graphql`
  mutation updateMerchantMutation($merchantId: ID!, $data: MerchantInput!) {
    merchant: updateMerchant(id: $merchantId, data: $data) {
      id
      name
      description
      ico
      officialName
      termsUrl
      virtual
      category {
        id
        name
      }
      shops {
        id
        address {
          location {
            lng
            lat
          }
          country
          street
          number
          city
          zip
        }
      }
      couponTemplates {
        id
        count
        value
        validUntil
      }
    }
  }
`;

export default updateMerchantMutation;
