import * as React from 'react';
import * as Sentry from '@sentry/browser';

export default class ErrorBoundary extends React.PureComponent {
  state = {
    error: null,
  };
  render() {
    return this.state.error ? (
      <>
        <h1>Error</h1>
        <pre>{this.state.error && this.state.error.toString()}</pre>
      </>
    ) : (
      this.props.children
    );
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }
}
