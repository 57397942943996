import idx from 'idx';
import { RecordSource, Environment, Store } from 'relay-runtime';
import {
  RelayNetworkLayer,
  urlMiddleware,
  cacheMiddleware,
  // perfMiddleware,
} from 'react-relay-network-modern';

import Logger from '_helpers/Logger';

import uploadMiddleware from './uploadMiddleware';

let AcceptLanguage = 'en';
let Authorization = null;

const source = new RecordSource();
const store = new Store(source);

const cacheTTL = parseInt(process.env.REACT_APP_GQL_CACHE, 10) || 0; // minutes
const middlewares = [];

/**
 * Url middleware - basic headers and endpoint
 */
middlewares.push(
  urlMiddleware({
    url: getEndpoint(),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
);

/**
 * Add locale header
 */
middlewares.push(function localeMiddleware(next) {
  return req => {
    if (Authorization) {
      req.fetchOpts.headers = {
        ...(req.fetchOpts.headers || {}),
        'Accept-Language': AcceptLanguage || navigator.language.substr(0, 2) || 'en',
      };
    }
    return next(req);
  };
});

/**
 * Add auth header if set
 */
middlewares.push(function authMiddleware(next) {
  return req => {
    if (Authorization) {
      req.fetchOpts.headers = {
        ...(req.fetchOpts.headers || {}),
        Authorization,
      };
    }
    return next(req);
  };
});

/**
 * Operation name to url
 */
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(function devMidleware(next) {
    return req => {
      const url = idx(req, _ => _.fetchOpts.url) || '';
      const operation = idx(req, _ => _.operation.name) || null;

      req.fetchOpts.url =
        operation && typeof operation === 'string' ? `${url}?operation=${operation}` : url;

      return next(req);
    };
  });
}

// Uploading middleware
middlewares.push(uploadMiddleware);

// TODO: make it better
middlewares.push(function unauthorizedMiddleware(next) {
  return req => {
    return next(req)
      .then(res => {
        if (!res.ok && res.status === 401) {
          window.location.reload();
        } else if (!res.ok) {
          Logger.error(res, req);
        }
        return res;
      })
      .catch(e => {
        if (e && e.res && e.res.ok === false && e.res.status === 401) {
          window.location.reload(); // unauthorized -> login
        } else {
          Logger.error(e, req); // log all other errors
        }
        throw e;
      });
  };
});

if (cacheTTL) {
  middlewares.push(
    cacheMiddleware({
      size: 100,
      ttl: cacheTTL * 60 * 1000,
    })
  );
}

if (process.env.REACT_APP_GQL_PERF) {
  // middlewares.push(perfMiddleware());
}

export function setLocaleHeader(locale) {
  AcceptLanguage = locale;
}

export function setBearerToken(token) {
  Authorization = `Bearer ${token}`;
}

export function getEndpoint() {
  return (process.env.REACT_APP_API_HOST || '') + (process.env.REACT_APP_GQL_ENDPOINT || '');
}

// TODO: Batch midleware = add server support

const options = {};

const network = new RelayNetworkLayer(middlewares, options);

const relayEnvironment = new Environment({
  store,
  network,
});

export default relayEnvironment;
