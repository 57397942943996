import * as Sentry from '@sentry/browser';

const Logger = {
  error: (error, extra) => {
    if (extra) {
      Sentry.withScope(scope => {
        scope.setExtra('extra', extra);
        Sentry.captureException(error);
      });
    } else {
      Sentry.captureException(error);
    }
  },
};

export default Logger;
