import React, { useState, useEffect } from 'react';
import { graphql } from 'relay-runtime';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useStatefullMutation } from '_components/Query';
import useTranslation from '_helpers/useTranslation';

const SecretKey = ({ merchantId }) => {
  const { t } = useTranslation();
  const SECRET_KEY_PLACEHOLDER = 'Pozor, starý kľúč bude zmenený';
  const [secretKey, setSecretKey] = useState('');

  const { action } = useStatefullMutation({
    mutation: createMutation,
    messages: {
      success: t('Nový kľúč vygenerovaný'),
      error: t('Ooops kľúč sa nepodarilo vygeneroť'),
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setSecretKey('');
    }, 16000);

    return () => {
      clearTimeout(timer);
    };
  }, [secretKey]);

  const generatSecretKey = () => {
    action({ merchantId }).then(({ ok, generateSecretKey }) => {
      if (ok) {
        setSecretKey(generateSecretKey);
      }
    });
  };

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="overline">{t('Generovanie nového e-commerce api kľúča')}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className={classes.key}>{secretKey ? secretKey : SECRET_KEY_PLACEHOLDER}</div>
        </Grid>
      </Grid>
      <Box mt={2} display="flex" justifyContent="flex-start">
        <Button
          variant="contained"
          component="button"
          color="secondary"
          type="button"
          onClick={generatSecretKey}
        >
          {t('Generovať')}
        </Button>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  key: {
    fontSize: '0.7rem',
  },
}));

const createMutation = graphql`
  mutation SecretKeyCreateMutation($merchantId: ID!) {
    generateSecretKey(merchantId: $merchantId)
  }
`;

export default SecretKey;
