import * as React from 'react';
import { graphql } from 'relay-runtime';
import RemoveIcon from '@material-ui/icons/Close';
import { ListItem, IconButton, ListItemText, ListItemSecondaryAction } from '@material-ui/core';

import Logger from '_helpers/Logger';
import useTranslation from '_helpers/useTranslation';
import { useStatefullMutation } from '_components/Query';

const Item = ({ merchantId, coupon }) => {
  const { t } = useTranslation();
  const { pending, action } = useStatefullMutation({
    mutation,
    variables: { id: coupon.id },
    messages: {
      success: t('Virtuálny kupón zmazaný'),
      error: t('Virtuálny kupón nebol zmazaný'),
    },
    updater: usePurgeCouponTemplate(merchantId, coupon.id),
  });

  const val = typeof coupon.value === 'number' ? coupon.value : 0;

  return (
    <ListItem key={coupon.id}>
      <ListItemText
        primary={`${val.toFixed(2)}€`}
        secondary={
          <i>
            {t(
              'platí {valid, plural, =0 {neobmedzene} one {# mesiac} few{# mesiace} other{# mesiacov}}',
              {
                valid: coupon.validUntil || 0,
              }
            )}
            {coupon.isFree ? t(' ( kupón je zadarmo )') : ''}
          </i>
        }
      />
      <ListItemSecondaryAction>
        <IconButton disabled={pending} edge="end" aria-label="delete" onClick={action}>
          <RemoveIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const usePurgeCouponTemplate = (merchantId, couponId) => (store, res) => {
  let merchant = null;
  try {
    merchant = store.get(merchantId);
    const list = merchant.getLinkedRecords('couponTemplates').filter(i => i._dataID !== couponId);
    merchant.setLinkedRecords(list, 'couponTemplates');
  } catch (e) {
    Logger.error(e);
  }
};

const mutation = graphql`
  mutation ItemDeleteVirtualCouponMutation($id: ID!) {
    deleteCouponTemplate(id: $id) {
      id
    }
  }
`;

export default Item;
