import { firebaseApp } from './firebaseApp';
import loginWithIdToken from '_helpers/loginWithIdToken';

/**
 * return email + password login handler
 */
function usePasswordLogin() {
  return (email, password, rememberMe) => {
    return firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(err => {
        if (err.code === 'auth/user-not-found') {
          return firebaseApp
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(_ => firebaseApp.auth().signInWithEmailAndPassword(email, password));
        }
        throw err;
      })
      .then(_ => firebaseApp.auth().currentUser.getIdToken())
      .then(idToken => {
        return loginWithIdToken({ idToken, rememberMe });
      });
  };
}

export default usePasswordLogin;
