/**
 * @flow
 * @relayHash 91202b7c4da3f2dc8ea6f5d27ecb4c95
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UsedCouponsQueryVariables = {|
  merchantId?: ?string,
  from: any,
  to: any,
|};
export type UsedCouponsQueryResponse = {|
  +usedCoupons: ?$ReadOnlyArray<?{|
    +at: any,
    +value: number,
    +coupon: {|
      +id: string
    |},
  |}>
|};
export type UsedCouponsQuery = {|
  variables: UsedCouponsQueryVariables,
  response: UsedCouponsQueryResponse,
|};
*/

/*
query UsedCouponsQuery(
  $merchantId: ID
  $from: Date!
  $to: Date!
) {
  usedCoupons(merchantId: $merchantId, from: $from, to: $to) {
    at
    value
    coupon {
      id
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'merchantId',
        type: 'ID',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'from',
        type: 'Date!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'to',
        type: 'Date!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'usedCoupons',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'from',
            variableName: 'from',
          },
          {
            kind: 'Variable',
            name: 'merchantId',
            variableName: 'merchantId',
          },
          {
            kind: 'Variable',
            name: 'to',
            variableName: 'to',
          },
        ],
        concreteType: 'CouponDeduction',
        plural: true,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'at',
            args: null,
            storageKey: null,
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'value',
            args: null,
            storageKey: null,
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'coupon',
            storageKey: null,
            args: null,
            concreteType: 'Coupon',
            plural: false,
            selections: [
              {
                kind: 'ScalarField',
                alias: null,
                name: 'id',
                args: null,
                storageKey: null,
              },
            ],
          },
        ],
      },
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'UsedCouponsQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'UsedCouponsQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    params: {
      operationKind: 'query',
      name: 'UsedCouponsQuery',
      id: null,
      text:
        'query UsedCouponsQuery(\n  $merchantId: ID\n  $from: Date!\n  $to: Date!\n) {\n  usedCoupons(merchantId: $merchantId, from: $from, to: $to) {\n    at\n    value\n    coupon {\n      id\n    }\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'c9a224c109d772332eaeabb6914fdaff';
module.exports = node;
