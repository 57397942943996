import { FastField } from 'formik';
import Box from '@material-ui/core/Box';
import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Form from '_components/Form';
import toastr from '_helpers/toastr';
import Logger from '_helpers/Logger';
import { useYup } from '_helpers/useYupMessages';
import useTranslation from '_helpers/useTranslation';
import { useResetPassword } from '_helpers/firebase';

function ForgotPassword({ children }) {
  const Yup = useYup();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSubmit = data => {
    useResetPassword(data.email)
      .then(({ ok }) => {
        if (ok) {
          toastr({ text: t('Email sent'), type: 'success' });
        } else {
          toastr({ text: t('Invalid credentials'), type: 'error' });
        }
      }) // eslint-disable-line
      .catch(error => {
        toastr({ text: error?.message, type: 'error' });
        Logger.error(error);
      });
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />

      <Form
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .nullable()
            .required()
            .email()
            .default(''),
        })}
        className={classes.form}
        onSubmit={handleSubmit}
        id="forgotPasswordForm"
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Forgot password')}
          </Typography>
        </Box>

        <Box mt={3} mb={3}>
          <FastField
            component={TextField}
            label="Email Address"
            autoComplete="email"
            variant="outlined"
            margin="dense"
            name="email"
            id="email"
            fullWidth
            required
            autoFocus
          />
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              className={classes.submit}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              {t('Reset password')}
            </Button>
          </Grid>
        </Grid>
      </Form>

      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://engerau.com/">
            Engerau Labs
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      display: 'flex',
      height: '100%',
      width: '100%',
    },
    body: {
      backgroundColor: theme.palette.common.white,
      height: '100%',
      width: '100%',
    },
    '#root': {
      display: 'flex',
      height: '100%',
    },
  },
  root: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
  },
  form: {
    marginTop: theme.spacing(8),
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '25rem',
    display: 'flex',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default ForgotPassword;
