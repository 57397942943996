/**
 * @flow
 * @relayHash 4df1d2a76dd734119279428e33273a46
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MerchantsShopsDeleteMutationVariables = {|
  id: string
|};
export type MerchantsShopsDeleteMutationResponse = {|
  +deleteShop: {|
    +deletedShopId: string
  |}
|};
export type MerchantsShopsDeleteMutation = {|
  variables: MerchantsShopsDeleteMutationVariables,
  response: MerchantsShopsDeleteMutationResponse,
|};
*/

/*
mutation MerchantsShopsDeleteMutation(
  $id: ID!
) {
  deleteShop(id: $id) {
    deletedShopId: id
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      kind: 'ScalarField',
      alias: 'deletedShopId',
      name: 'id',
      args: null,
      storageKey: null,
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'MerchantsShopsDeleteMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'deleteShop',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'Shop',
          plural: false,
          selections: [(v2 /*: any*/)],
        },
      ],
    },
    operation: {
      kind: 'Operation',
      name: 'MerchantsShopsDeleteMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'deleteShop',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'Shop',
          plural: false,
          selections: [
            (v2 /*: any*/),
            {
              kind: 'ScalarField',
              alias: null,
              name: 'id',
              args: null,
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      operationKind: 'mutation',
      name: 'MerchantsShopsDeleteMutation',
      id: null,
      text:
        'mutation MerchantsShopsDeleteMutation(\n  $id: ID!\n) {\n  deleteShop(id: $id) {\n    deletedShopId: id\n    id\n  }\n}\n',
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'da64412caa630e410eea0b3526744e48';
module.exports = node;
