import * as React from 'react';
import { TextField } from 'formik-material-ui';

const NumericField = ({ field, form, ...props }) => {
  return (
    <TextField
      {...props}
      form={form}
      field={{
        ...field,
        value: field.value ?? 0,
        onBlur: e => {
          const val = Number(Number(e.target.value.replace(',', '.') || 0).toFixed(2)) || 0;
          field.onBlur(e);
          form.setFieldValue(field.name, val, true);
        },
        onChange: e => {
          form.setFieldValue(field.name, e.target.value, false);
        },
      }}
    />
  );
};

export default NumericField;
