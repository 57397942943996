import * as React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import { useLocation } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { makeStyles } from '@material-ui/core/styles';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { IconButton, Tooltip, Typography } from '@material-ui/core';

import useTranslation from '_helpers/useTranslation';
import { IdentityContext } from '_helpers/Identity/Identity';

// -------------------------------------------------------------------------------------------------

const Layout = ({ children, requestCreateCategory }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const loc = useLocation();
  const { isAdmin } = React.useContext(IdentityContext);

  const isMerchant = loc.pathname === '/merchant';
  const isHome = loc.pathname === '/' || loc.pathname === '/dashboard';

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems="center">
            {isAdmin && (
              <>
                <Button
                  variant={(isHome && 'outlined') || undefined}
                  component={Link}
                  color="inherit"
                  to="/dashboard"
                  size="small"
                >
                  {t('Prehľad')}
                </Button>
                <Button
                  variant={(isMerchant && 'outlined') || undefined}
                  startIcon={<AddIcon />}
                  component={Link}
                  color="inherit"
                  to="/merchant"
                  size="small"
                >
                  {t('Nový predajca')}
                </Button>
              </>
            )}
            <div className={classes.right}>
              {isAdmin && requestCreateCategory && (
                <Tooltip title={t('Nová kategória')}>
                  <IconButton color="inherit" onClick={requestCreateCategory}>
                    <PostAddIcon />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title={t('Odhlásiť')}>
                <IconButton to="/auth/logout" component={Link} color="inherit">
                  <LogoutIcon />
                </IconButton>
              </Tooltip>

              <Typography variant="caption">v.{process.env.REACT_APP_VERSION}</Typography>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container fixed className={classes.content}>
        {children}
      </Container>
    </div>
  );
};

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  right: {
    marginLeft: 'auto',
    marginRight: '0.5rem',
  },
  toolbar: {
    width: theme.size.container,
    margin: '0 auto',
    maxWidth: '100%',

    '& .MuiButton-sizeSmall': {
      height: '2rem',
      marginLeft: '0.5rem',
    },
  },
  content: {
    marginTop: '2rem',
    marginBottom: '2rem',
    minWidth: '20rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100% !important',
    },
  },
}));

export default React.memo(Layout);
