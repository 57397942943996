import { firebaseApp } from './firebaseApp';

function useResetPassword(email) {
  return firebaseApp
    .auth()
    .sendPasswordResetEmail(email)
    .then(function() {
      return { ok: true };
    })
    .catch(function(error) {
      return error;
    });
}

export default useResetPassword;
