import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const SelectField = ({ field, fullWidth, margin, options, variant, label, disabled }) => {
  const handleChange = field.onChange;
  return (
    <FormControl fullWidth margin={margin} variant={variant}>
      <InputLabel htmlFor="age-simple">{label}</InputLabel>
      <Select
        onChange={handleChange}
        disabled={disabled}
        value={field.value}
        name={field.name}
        variant={variant}
        margin={margin}
      >
        {options &&
          options.length > 0 &&
          options.map(({ value, label }) => (
            <MenuItem value={value} key={value} selected={field.value === value}>
              {label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
