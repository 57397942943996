const firebaseConfig = {
  apiKey: 'AIzaSyDM4QAt1PgDpkAWjpY8W-FcS0PdGkUPmpk',
  authDomain: 'prispay-563a0.firebaseapp.com',
  databaseURL: 'https://prispay-563a0-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'prispay-563a0',
  storageBucket: 'prispay-563a0.appspot.com',
  messagingSenderId: '109411342608',
  appId: '1:109411342608:web:ff32e39b9a048e4cb0ed95',
  measurementId: 'G-K17BB2XD79',
};

export default firebaseConfig;
