import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles, CircularProgress } from '@material-ui/core';

import { IdentityContext } from './Identity';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = React.useContext(IdentityContext);
  const classes = useStyles(loading);
  if (loading) {
    return (
      <div className={classes.init}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return user ? children : <Redirect to="/auth" />;
};

const expand = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  width: '100%',
};

const useStyles = makeStyles({
  '@global': {
    '#root, body, html': loading => (loading ? expand : {}),
  },
  init: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
});

export default ProtectedRoute;
