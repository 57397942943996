import * as yup from 'yup';
import { useMemo, useContext, createContext } from 'react';

import useTranslation from '_helpers/useTranslation';

const useYupMessages = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    const translations = {
      mixed: {
        default: () => t('Neplatná hodnota'),
        required: () => t('Hodnota je povínná'),
        oneOf: ({ values }) =>
          t('Hodnota musí byt jednou z radu: {values}', {
            values,
          }),
        notOneOf: ({ values }) =>
          t('Hodnota nesmie byť jednou z radu: {values}', {
            values,
          }),
        notType: params => {
          const { type, originalValue } = params;

          // Typeof null === 'object' so it would be confusing
          const actualType = originalValue === null ? null : typeof originalValue;
          return t('Hodnota musí byť {expectedType}, ale je {actualType}.', {
            expectedType: type,
            actualType,
          });
        },
      },

      string: {
        length: ({ length }) => t('Reťazec musí mať presne {length} znakov', { length }),
        min: ({ min }) => t('Reťazec musí mať najmenej {min} znakov', { min }),
        max: ({ max }) => t('Reťazec môže mať najviac {max} znakov', { max }),
        matches: params => {
          return t('Hodnota musí mať tvar: {regex}"', {
            regex: params.regex,
          });
        },
        email: () => t('Neplatný email'),
        url: () => t('Neplatný URL link'),
        trim: () => t('Nesmie začínať ani končiť medzerou'),
        lowercase: () => t('Musí byť zadaný malými písmenami'),
        uppercase: () => t('Musí byť zadaný veľkými písmenami'),
      },

      number: {
        min: ({ min }) => t('Hodnota musí byť viac alebo {min}', { min }),
        max: ({ max }) => t('Hodnota musí byť menej alebo {max}', { max }),
        lessThan: ({ less }) => t('Hodnota musí byť menej než {less}', { less }),
        moreThan: ({ more }) => t('Hodnota musí byť viac než {more}', { more }),
        notEqual: ({ notEqual }: { notEqual: number }) =>
          t('Hodnota nesmie byť {notEqual}', { notEqual }),
        positive: () => t('Hodnota musí byť pozitívne číslo'),
        negative: () => t('Hodnota musí byť negatívne číslo'),
        integer: () => t('Hodnota musí byť celé číslo'),
      },

      date: {
        min: ({ min }) =>
          t('Hodnota musí byť najskôr {min}', {
            min: new Date(min).toISOString(),
          }),
        max: ({ max }) =>
          t('Hodnota musí byť najneskôr {max}', {
            max: new Date(max).toISOString(),
          }),
      },

      object: {
        noUnknown: () => t('Niesú povolené ďalšie hodnoty'),
      },

      array: {
        min: ({ min }) => t('Pole musí mať aspoň {min} prvkov', { min }),
        max: ({ max }) => t('Pole môže mať najviac {max} prvkov', { max }),
      },
    };

    yup.setLocale(translations);
    return yup;
  }, [t]);
};

export default useYupMessages;

export const yupContext = createContext(yup);

export const useYup = () => {
  return useContext(yupContext);
};
