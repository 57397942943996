import * as React from 'react';
import { QueryRenderer } from 'react-relay';

import relayEnvironment from './relayEnvironment';
// -------------------------------------------------------------------------------------------------

/**
 * children: ({ loading: boolean, error: *, data: *, retry: ?(*) => void }) => React.Node,
 * query: $Keys<typeof queryMap>,
 * variables?: { [string]: * }
 */
const Query = ({ render, ...props }) => {
  return (
    <QueryRenderer
      {...props}
      render={({ retry, error, props, ...rest }) =>
        render({ loading: !retry, error, data: props, retry })
      }
      environment={relayEnvironment}
    />
  );
};

export default Query;
