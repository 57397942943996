import { graphql } from 'relay-runtime';

const createMerchantMutation = graphql`
  mutation createMerchantMutation($data: MerchantInput!) {
    merchant: createMerchant(data: $data) {
      id
      name
      description
      ico
      officialName
      termsUrl
      virtual
      category {
        id
        name
      }
      shops {
        id
        address {
          location {
            lng
            lat
          }
          country
          street
          number
          city
          zip
        }
      }
      couponTemplates {
        id
        count
        value
        validUntil
      }
    }
  }
`;

export default createMerchantMutation;
