import * as React from 'react';
import propTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import { TextField } from 'formik-material-ui';
import InfoIcon from '@material-ui/icons/Info';
import { FastField, FormikConsumer } from 'formik';
import { Tooltip, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Form from '_components/Form';
import Avatar from '_components/Avatar';
import { useYup } from '_helpers/useYupMessages';
import NumericField from '_components/NumericField';
import useTranslation from '_helpers/useTranslation';
import LabeledSwitchField from '_components/LabeledSwitchField';
import { AutocompleteSelectField } from '_components/AutocompleteSelect';
import UsedCoupons from './UsedCoupons';
import SoldCoupons from './SoldCoupons';

// -------------------------------------------------------------------------------------------------

const MerchantForm = ({
  requestAvatarAction,
  categories,
  onSubmit,
  merchant,
  children,
  mode,
  signedInUser,
}) => {
  const { t } = useTranslation();
  const Yup = useYup();

  // TODO: move to hook
  const schema = Yup.object().shape({
    name: Yup.string()
      .nullable()
      .required()
      .min(3)
      .default(''),
    active: Yup.boolean()
      .required()
      .default(true),
    share: Yup.number()
      .nullable()
      .required()
      .min(0)
      .max(100)
      .default(3),
    categoryId: Yup.string()
      .nullable()
      .required()
      .default(null),
    ico: Yup.string()
      .nullable()
      .required()
      .default(''),
    officialName: Yup.string()
      .nullable()
      .required()
      .default(''),
    termsUrl: Yup.string()
      .nullable()
      .required()
      .url()
      .default(''),
    description: Yup.string()
      .nullable()
      .min(10)
      .default(''),
    virtual: Yup.boolean()
      .required()
      .default(false),
    image: Yup.mixed()
      .nullable()
      .default(null),
  });

  if (mode === 'edit' && !merchant) {
    return <Redirect to="/create-merchant" />;
  }

  const isAdmin = ['ADMIN', 'SUPERADMIN'].indexOf(signedInUser.role) > -1;
  const isSuperAdmin = ['SUPERADMIN'].indexOf(signedInUser.role) > -1;

  let initials = {};
  if (merchant) {
    initials = {
      ...merchant,
      image: merchant.image && merchant.image.url,
      categoryId: merchant.category && merchant.category.id,
    };
  }

  return (
    <>
      <Form
        validationSchema={schema}
        initialValues={initials}
        onSubmit={onSubmit}
        id="merchantForm"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h5">
              {mode === 'edit' ? t('Upraviť obchodníka') : t('Pridať obchodníka')}
              <FormikConsumer>
                {({ values }) =>
                  !values.active && (
                    <Tooltip
                      title={t(
                        'Profil obchodníka je neviditeľný pre používateľov a administrátory sa nemôžu prihlásiť'
                      )}
                    >
                      <Typography variant="caption">{t(' (koncept)')}</Typography>
                    </Tooltip>
                  )
                }
              </FormikConsumer>
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="overline">{t('Základné nastavenia')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={9} lg={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FastField
                  label={t('Názov predajcu')}
                  component={TextField}
                  variant="outlined"
                  name="name"
                  fullWidth
                />
              </Grid>
              {isSuperAdmin && (
                <>
                  <Grid style={{ display: 'flex' }} xs={12} sm={6} md={3} lg={3} item>
                    <FastField
                      label={val => (val ? t('Aktívny') : t('Neaktívny'))}
                      component={LabeledSwitchField}
                      name="active"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <FastField
                      InputProps={{ endAdornment: '%' }}
                      label={t('Loki podiel')}
                      component={NumericField}
                      variant="outlined"
                      name="share"
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              {/* TODO: finish autocomplete */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FastField
                  component={AutocompleteSelectField}
                  label={t('Kategória')}
                  options={categories}
                  variant="outlined"
                  name="categoryId"
                  fullWidth
                />
              </Grid>

              <Grid style={{ display: 'flex' }} xs={12} sm={6} md={3} lg={3} item>
                <FastField
                  label={t('IČO')}
                  component={TextField}
                  variant="outlined"
                  name="ico"
                  fullWidth
                />
              </Grid>

              <Grid style={{ display: 'flex' }} xs={12} sm={6} md={4} lg={4} item>
                <FastField
                  label={t('Obchodné meno')}
                  component={TextField}
                  variant="outlined"
                  name="officialName"
                  fullWidth
                />
              </Grid>

              <Grid style={{ display: 'flex' }} xs={12} sm={6} md={5} lg={5} item>
                <FastField
                  label={t('Obchodné podmienky')}
                  component={TextField}
                  variant="outlined"
                  name="termsUrl"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Grid
              style={{ height: '100%' }} // TODO: remove inline style
              alignItems="center"
              justify="center"
              container
            >
              <FormikConsumer>
                {formik => (
                  <Avatar
                    onClick={merchant && requestAvatarAction}
                    value={formik.values.image}
                    size={120}
                  />
                )}
              </FormikConsumer>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <FastField
              component={TextField}
              label={t('O firme')}
              name="description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FastField
              label={val =>
                val ? t('Firma Akceptuje LOKI kupóny') : t('Firma Neakceptuje LOKI kupóny')
              }
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={t(
                      'LOKI kupóny sú virtuálne kupóny ktoré vznikajú v momente zakúpenia a majú zadanú platnosť od dátumu nákupu. Vlastné kupóny sa musia nahrať s konkrétnym dátumom platnosti, alebo platia neobmedzene dlho.'
                    )}
                  >
                    <InfoIcon />
                  </Tooltip>
                ),
              }}
              component={LabeledSwitchField}
              disabled={!!merchant}
              name="virtual"
            />
          </Grid>
        </Grid>
        {children}
        <Box mt={2} display="flex" justifyContent="flex-end">
          {isAdmin && mode === 'edit' && <UsedCoupons merchantId={merchant?.id} />}
          {isAdmin && mode === 'edit' && <SoldCoupons merchantId={merchant?.id} />}
          <FormikConsumer>
            {({ dirty, isSubmitting }) => (
              <Button
                startIcon={<SaveIcon />}
                disabled={isSubmitting}
                form="merchantForm"
                variant="contained"
                component="button"
                aria-label="save"
                color="secondary"
                type="submit"
              >
                {merchant ? t('Uložiť') : t(' Pridať')}
              </Button>
            )}
          </FormikConsumer>
        </Box>
      </Form>
    </>
  );
};

MerchantForm.propTypes = {
  categories: propTypes.array.isRequired,
};

export default MerchantForm;
