import React from 'react';
import { FastField } from 'formik';
import { graphql } from 'relay-runtime';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';

import Form from '_components/Form';
import Logger from '_helpers/Logger';
import Dialog from '_components/Dialog';
import { useYup } from '_helpers/useYupMessages';
import useTranslation from '_helpers/useTranslation';
import { useStatefullMutation } from '_components/Query';

const CategoryModal = ({ open, requestClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const Yup = useYup();

  const { action, pending } = useStatefullMutation({
    mutation: createMutation,
    messages: {
      success: t('Nová kategória pridaná'),
      error: t('Ooops kategória nebola pridaná'),
    },
    updater: (store, res) => {
      try {
        const c = store.getRootField('createMerchantCategory');
        const cl = store.getRoot().getLinkedRecords('categories');
        cl.push(c);
        store.getRoot().setLinkedRecords(cl, 'categories');
      } catch (e) {
        Logger.error(e);
      }
    },
  });

  const managedClose = React.useCallback(() => {
    !pending && requestClose();
  }, [pending, requestClose]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .nullable()
      .required()
      .default('')
      .min(5),
  });

  return (
    <Dialog
      title={t('Nová kategória')}
      onClose={managedClose}
      maxWidth="md"
      open={open}
      actions={[
        <Button key="cancel" onClick={requestClose}>
          {t('Zrušiť')}
        </Button>,
        <Button
          form="categoryForm"
          variant="contained"
          disabled={pending}
          color="secondary"
          type="submit"
          key="add"
        >
          {t('Pridať kategóriu')}
        </Button>,
      ]}
    >
      <Form
        onSubmit={data => action(data).then(res => res.ok && requestClose())}
        validationSchema={validationSchema}
        id="categoryForm"
      >
        <Grid container className={classes.content}>
          <Grid item xs={12}>
            <FastField
              label={t('Názov kategórie')}
              component={TextField}
              variant="outlined"
              name="name"
              fullWidth
            />
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
};

const createMutation = graphql`
  mutation CategoryModalCreateMutation($name: String!) {
    createMerchantCategory(name: $name) {
      id
      name
    }
  }
`;

const useStyles = makeStyles({
  content: {
    width: 9999,
    maxWidth: '100%',
  },
});

export default CategoryModal;
