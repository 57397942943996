import Toastify from 'toastify-js';

import 'toastify-js/src/toastify.css';

const toastr = ({ text, type = 'success' }) => {
  Toastify({
    text,
    duration: 3000,
    // destination: 'https://github.com/apvarun/toastify-js',
    newWindow: true,
    close: true,
    gravity: 'bottom', // `top` or `bottom`
    position: 'left', // `left`, `center` or `right`
    backgroundColor:
      type === 'success'
        ? 'linear-gradient(to right, #00b09b, #96c93d)'
        : 'linear-gradient(to right, rgb(255, 95, 109), rgb(255, 195, 113))',
    stopOnFocus: true, // Prevents dismissing of toast on hover
    // onClick: function() {}, // Callback after click
  }).showToast();
};

export default toastr;
