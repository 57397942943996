import { graphql } from 'relay-runtime';
import React from 'react';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/styles';

import useTranslation from '_helpers/useTranslation';
import Query from '_components/Query';

const SoldCoupons = ({ merchantId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const today = new Date();

  // TODO: replace from with some value in future
  return (
    <Query
      variables={{ merchantId, from: '2020-10-09T00:00:00Z', to: today.toISOString() }}
      query={soldCouponsQuery}
      render={({ data, loading, retry }) => {
        const soldCoupons = !loading && data && data.soldCoupons;

        if ((soldCoupons && !soldCoupons.length) || loading) {
          return null;
        }

        const headers = [
          { label: t('ID'), key: 'id' },
          { label: t('Suma'), key: 'value' },
          { label: t('Dátum zakúpenia'), key: 'created' },
        ];

        const soldCouponArray =
          soldCoupons &&
          soldCoupons.length > 0 &&
          soldCoupons.map(soldCoupon => {
            const { id, value, created } = soldCoupon || {};
            return { id, value, created };
          });

        return (
          <CSVLink
            className={classes.csvLink}
            data={soldCouponArray}
            headers={headers}
            target="_blank"
            filename={'coupons_sold.csv'}
          >
            {t('Predané kupóny CSV')}
          </CSVLink>
        );
      }}
    />
  );
};

const useStyles = makeStyles(theme => ({
  csvLink: {
    color: '#88b369',
    padding: '6px 16px',
    fontSize: '0.875rem',
    marginRight: 10,
    fontWeight: 500,
    lineHeight: 1.75,
  },
}));

export default SoldCoupons;

const soldCouponsQuery = graphql`
  query SoldCouponsQuery($merchantId: ID, $from: Date!, $to: Date!) {
    soldCoupons(merchantId: $merchantId, from: $from, to: $to) {
      id
      value
      created
    }
  }
`;
