import React, { memo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UploadIcon from '@material-ui/icons/CloudUpload';

import {
  Grid,
  List,
  Divider,
  ListItem,
  IconButton,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';

import useTranslation from '_helpers/useTranslation';
import { useOpenDialog } from '../MerchantDetail';

const CustomCoupons = ({ coupons, virtual, onRequestImport }) => {
  const { t } = useTranslation();
  const openImportDialog = useOpenDialog('importCoupons');

  return (
    <List
      dense
      subheader={
        <Grid container justify="space-between">
          <ListSubheader>{t('Vlastné kupony')}</ListSubheader>
          <Grid item>
            <Tooltip title={t('Nahrať nové kupóny')}>
              <IconButton aria-label="upload-shops" onClick={openImportDialog}>
                <UploadIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      }
    >
      <Divider />
      {coupons &&
        coupons.length > 0 &&
        coupons.map(coupon => (
          <ListItem key={coupon.id}>
            <ListItemText
              primary={`${coupon.value.toFixed(2)}€`}
              secondary={
                <i>
                  {t('Predané {used}', {
                    used: coupon.sold || 0,
                  })}
                  {!virtual &&
                    t(', zostáva {left}', {
                      left: coupon.count || 0,
                    })}
                </i>
              }
            />
          </ListItem>
        ))}
    </List>
  );
};

export default memo(CustomCoupons);
