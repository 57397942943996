import * as React from 'react';
import { FormikConsumer } from 'formik';
import { Grid, Typography, Button, Box, makeStyles } from '@material-ui/core';

import Admins from './Admins';
import CustomCoupons from './CustomCoupons';
import MerchantsShops from './MerchantsShops';
import VirtualCouponsList from './VirtualCoupons';
import useTranslation from '_helpers/useTranslation';

const Lists = ({ merchant }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <FormikConsumer>
          {({ values }) =>
            values.virtual !== merchant.virtual ? (
              <Box p={4} color="primary" className={classes.alert}>
                <Box mb={2}>
                  <Typography variant="h6">{t('Zmena kupónov')}</Typography>
                </Box>
                <Typography variant="body1">
                  {t('Zmenou typu kupónov dôjde k zmazaniu všetkých existujúcich kupónov.')}{' '}
                  <Button color="secondary" type="submit">
                    {t('Uložiť')}
                  </Button>
                </Typography>
              </Box>
            ) : merchant.virtual ? (
              <VirtualCouponsList coupons={merchant.couponTemplates} merchantId={merchant.id} />
            ) : (
              <CustomCoupons
                coupons={merchant.couponTemplates}
                virtual={merchant.virtual}
                merchantId={merchant.id}
              />
            )
          }
        </FormikConsumer>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Admins merchantId={merchant.id} admins={merchant.admins} />
          </Grid>

          <Grid item xs={12}>
            <MerchantsShops merchant={merchant} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  alert: {
    background: theme.palette.danger[50],
    border: '1px solid ' + theme.palette.danger.A100,
  },
}));

export default Lists;
