import * as React from 'react';
import MuiDownshift from 'mui-downshift';

const AutocompleteSelect = ({
  blurOnSelect = true,
  placeholder,
  helperText,
  InputProps,
  onChange,
  options,
  onBlur,
  error,
  label,
  name,
  value,
  ...props
}) => {
  const [filteredItems, setFilteredItems] = React.useState(options);
  const inputRef = React.useRef();
  const count = React.useRef(0);

  React.useEffect(() => setFilteredItems(options), [options]);

  const handleStateChange = ch => {
    if (ch.hasOwnProperty('selectedItem') && ch.selectedItem !== undefined) {
      onChange({ target: { value: (ch.selectedItem && ch.selectedItem.value) || null } });
      setFilteredItems(options);
    } else if (typeof ch.inputValue === 'string') {
      const filteredItems = options.filter(item =>
        item.label.toLowerCase().includes(ch.inputValue.toLowerCase())
      );
      setFilteredItems(filteredItems);
    }
  };

  const selectedItem = filteredItems.find(i => i.value === value);

  return (
    <MuiDownshift
      onStateChange={handleStateChange}
      items={filteredItems}
      focusOnClear
      {...props}
      inputRef={ref => (inputRef.current = ref)}
      getInputProps={p => {
        if (!count.current) {
          count.current++;
          setTimeout(() => p.selectItem(selectedItem), 150);
        }
        return {
          label,
          error: !!error,
          helperText: error || helperText,
          ...(InputProps || {}),
          onBlur,
        };
      }}
    />
  );
};

export default AutocompleteSelect;
