const printAddress = a => {
  const address = a || {};
  return [
    [address.street, address.number].filter(i => i).join(' '),
    [address.zip, address.city].filter(i => i).join(' '),
    address.country,
  ]
    .filter(i => i)
    .join(', ');
};

export default printAddress;
