import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { RelayEnvironmentProvider } from 'relay-hooks';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Switch, Route, Router } from 'react-router-dom';

import theme from '_assets/theme';
import routerHistory from '_helpers/routerHistory';
import ErrorBoundary from '_components/ErrorBoundary';
import { FirebaseAuthProvider } from '_helpers/firebase';
import Identity, { ProtectedRoute } from '_helpers/Identity';

import { relayEnvironment } from '_components/Query';
import useYupMessages, { yupContext } from '_helpers/useYupMessages';

import Auth from './pages/Auth';
import AdminWrapper from './AdminWrapper';

function App() {
  const localisedYup = useYupMessages();
  const YupProvider = yupContext.Provider;

  return (
    <ErrorBoundary>
      <YupProvider value={localisedYup}>
        <RelayEnvironmentProvider environment={relayEnvironment}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <FirebaseAuthProvider>
              <Identity>
                <Router history={routerHistory}>
                  <Switch>
                    <Route path="/auth" component={Auth} />
                    <Route component={Admin} />
                  </Switch>
                </Router>
              </Identity>
            </FirebaseAuthProvider>
          </ThemeProvider>
        </RelayEnvironmentProvider>
      </YupProvider>
    </ErrorBoundary>
  );
}

function AdminComponent() {
  return (
    <ProtectedRoute>
      <AdminWrapper />
    </ProtectedRoute>
  );
}

const Admin = React.memo(AdminComponent);

export default App;
